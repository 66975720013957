import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import HighlightedHints from "./highlighed-question";
import HintsView from "./hints-viewer";
import ImageUpload from "./question-image";
import QuestionTextContainer from "./question-text-container";
import QuestionTip from "./question-tip-container";
import SelectionArea from "./selection-area";
import { apiRequest } from "(apis)/api-interface/api-handler";
import ReactSelect from "react-select";

interface Props {
  mode?: string;
  form: any;
  setForm: any;
  modules: any;
}

export default function Page1({ mode, form, setForm, modules }: Props) {
  const [instituteData, setInstitutesData] = useState([]);

  const getInstituteData = async () => {
    try {
      const res = await apiRequest("getinstitutes");
      if (res?.data?.isSuccess) {
        const resData = res?.data?.institutes;
        if (resData?.length > 0) {
          const formatedData = resData.map((item: any) => {
            return {
              ...item,
              label: item.instituteName,
              value: item.instituteName,
            };
          });
          setInstitutesData(formatedData);
        } else {
          setInstitutesData([]);
        }
      }
    } catch (error) {
      console.log("error,", error);
    }
  };

  useEffect(() => {
    getInstituteData();
  }, []);

  const [subjectValues, setSubjectValues] = useState([]);
  const [topicValues, setTopicValues] = useState([]);
  const [medschoolSubjects, setMedschoolSubjects] = useState([]);

  const fetchMedschoolSubjects = async () => {
    try {
      const response = await apiRequest("getDisciplines");
      if (response?.status === 200) {
        const modules = response?.data?.disciplines?.map((item) => {
          return item?.disciplineName;
        });
        const data = modules || [];

        setMedschoolSubjects(data);
      }
    } catch (error) {
      console.error("Error getting modules data:", error);
    }
  };

  const GetSubjects = async () => {
    const response = await apiRequest("getSubjects");
    let uniqueSubjects = [];
    response.data.map((item: any) => {
      uniqueSubjects.push(item.name);
    });
    setSubjectValues(uniqueSubjects);
  };

  const GetTopics = async () => {
    const response = await apiRequest("getalltopics");
    setTopicValues(response.data.data);
  };

  useEffect(() => {
    GetSubjects();
    GetTopics();
    fetchMedschoolSubjects();
  }, []);

  const [tag, setTag] = useState("");
  const AddMiscTag = (tag) => {
    setForm({ ...form, miscTags: [...form.miscTags, tag] });
  };

  const Types = [
    "Past Paper Question",
    "Practice Question",
    "Mock Test Question",
  ];

  function filterInstitutesByCategory(instituteData) {
    return instituteData.filter(
      (institute) =>
        institute.metadata.category === process.env.REACT_APP_CATEGORY ||
        institute.metadata.category ===
          process.env.REACT_APP_CATEGORY.replace("-", " ")
    );
  }

  function filterTopicsByCategory(topics) {
    const selectedCategory = process.env.REACT_APP_CATEGORY;
    if (selectedCategory === "PRE-MEDICAL") {
      return topics.filter(
        (topic: any) => topic.metadata.category === selectedCategory
      );
    } else {
      return topics;
    }
  }

  // Filtering the institutes
  const filteredInstitutes = filterInstitutesByCategory(instituteData);
  const filteredTopics = filterTopicsByCategory(topicValues);

  const isMedSchoolSelected =
    process.env.REACT_APP_ENTITY === "UNDERGRADUATE" &&
    process.env.REACT_APP_CATEGORY === "MEDSCHOOL";

  return (
    <>
      <div className="overflow-hidden text-semibold p-12 pt-4">
        <div className="mx-auto">
          <SelectionArea setForm={setForm} form={form} mode={mode} />
        </div>
        <Divider />

        <div className="text-md font-bold text-primary">META DATA</div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-gray-50 rounded p-4 shadow-md">
          <div className="flex flex-col gap-2 border-r-2 border-gray-200 rounded pr-2">
            <div className="flex flex-col gap-2 border-r-2 border-gray-200 rounded pr-2">
              <div className="text-md font-bold text-primary">
                Question Type
              </div>
              <div className="flex gap-2 items-center justify-between">
                <div className="text-md font-semibold text-gray-600">
                  {isMedSchoolSelected ? "Discipline" : "Subject"}
                </div>
                <Select
                  placeholder={`Select ${
                    isMedSchoolSelected ? "Discipline" : "Subject"
                  }`}
                  onChange={(value) => {
                    setForm({ ...form, subject: value });
                  }}
                  className="w-1/2"
                  disabled={
                    !process.env.REACT_APP_ENTITY ||
                    process.env.REACT_APP_ENTITY === "" ||
                    !process.env.REACT_APP_CATEGORY ||
                    process.env.REACT_APP_CATEGORY === ""
                  }
                  value={form.subject === "" ? "Select Subject" : form.subject}
                >
                  {(isMedSchoolSelected
                    ? medschoolSubjects
                    : subjectValues
                  ).map((subject) => (
                    <Select.Option key={subject} value={subject}>
                      {subject}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="flex gap-2 items-center justify-between">
                <div className="text-md font-semibold text-gray-600">Type</div>
                <Select
                  placeholder="Select Type"
                  onChange={(value) => {
                    setForm({ ...form, type: value });
                  }}
                  className="w-1/2"
                  disabled={
                    !process.env.REACT_APP_ENTITY ||
                    process.env.REACT_APP_ENTITY === "" ||
                    !process.env.REACT_APP_CATEGORY ||
                    process.env.REACT_APP_CATEGORY === ""
                  }
                  value={form.type === "" ? "Select Type" : form.type}
                >
                  {Types.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="flex gap-2 items-center justify-between">
                <div className="text-md font-semibold text-gray-600">
                  University
                </div>
                <Select
                  mode="multiple"
                  placeholder="Select university"
                  onChange={(value) => {
                    setForm({ ...form, university: value });
                  }}
                  disabled={
                    !process.env.REACT_APP_ENTITY ||
                    process.env.REACT_APP_ENTITY === "" ||
                    !process.env.REACT_APP_CATEGORY ||
                    process.env.REACT_APP_CATEGORY === ""
                  }
                  className="w-1/2"
                  value={form.university || []}
                >
                  {filteredInstitutes?.map((university: any) => (
                    <Select.Option
                      key={university.value}
                      value={university.value}
                    >
                      {university.value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 border-r-2 border-gray-200 rounded pr-2">
            <div className="text-md font-bold text-primary">Tags</div>
            <div className="flex gap-2 items-center justify-between">
              <div className="text-md font-semibold text-gray-600">Topic</div>
              {/* <Select
                placeholder="Select Topic"
                showSearch
                disabled={
                  !process.env.REACT_APP_ENTITY ||
                  process.env.REACT_APP_ENTITY === "" ||
                  !process.env.REACT_APP_CATEGORY ||
                  process.env.REACT_APP_CATEGORY === ""
                }
                onChange={(value) => {
                  setForm({ ...form, topic: value });
                }}
                className="w-[80%]"
                value={form.topic === "" ? "Select Topic" : form.topic}
              >
                {filteredTopics?.map((topic: any) => (
                  <Select.Option key={topic.topicName} value={topic.topicName}>
                    {topic.topicName}
                  </Select.Option>
                ))}
              </Select> */}

              <ReactSelect
                options={filteredTopics.map((topic: { topicName: any }) => ({
                  label: topic.topicName,
                  value: topic.topicName,
                }))}
                isDisabled={
                  !process.env.REACT_APP_ENTITY ||
                  process.env.REACT_APP_ENTITY === "" ||
                  !process.env.REACT_APP_CATEGORY ||
                  process.env.REACT_APP_CATEGORY === ""
                }
                value={
                  form.topic ? { label: form.topic, value: form.topic } : null // Set to null if no topic is selected to clear the selection
                }
                isClearable
                placeholder="Select Topic"
                isSearchable
                className="w-full"
                onChange={(selectedOption: any) => {
                  if (selectedOption?.value) {
                    setForm({ ...form, topic: selectedOption.value });
                  }
                }}
              />
            </div>

            <div className="flex gap-2 items-center justify-between">
              <div className="text-md font-semibold text-gray-600">
                Sub-Topic
              </div>
              <Select
                placeholder="Select Sub-Topic"
                showSearch
                mode="multiple"
                disabled={form.topic ? false : true}
                onChange={(value) => {
                  setForm({ ...form, subTopic: value });
                }}
                className="w-1/2"
                value={
                  form.subTopic === "" ? "Select Sub-Topic" : form.subTopic
                }
              >
                {filteredTopics
                  .filter((topic: any) => topic?.topicName === form.topic)
                  .map((topic: any) =>
                    topic?.subTopics?.map((subTopic: any) => (
                      <Select.Option
                        key={subTopic?.subTopicName}
                        value={subTopic?.subTopicName}
                      >
                        {subTopic?.subTopicName}
                      </Select.Option>
                    ))
                  )}
              </Select>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <div className="text-md font-semibold text-gray-600">
                University Year
              </div>
              <Select
                placeholder="Select Year"
                onChange={(value) => {
                  setForm({ ...form, year: value });
                }}
                disabled={
                  !process.env.REACT_APP_ENTITY ||
                  process.env.REACT_APP_ENTITY === "" ||
                  !process.env.REACT_APP_CATEGORY ||
                  process.env.REACT_APP_CATEGORY === ""
                }
                className="w-1/2"
                value={form.year === 0 ? "Select Year" : form.year}
              >
                {Array.from({ length: 30 }, (_, i) => i + 2000).map((year) => (
                  <Select.Option key={year} value={year}>
                    {year}
                  </Select.Option>
                ))}
                <Select.Option key={"1st Year"} value={1}>
                  1st Year
                </Select.Option>
                <Select.Option key={"2nd Year"} value={2}>
                  2nd Year
                </Select.Option>
                <Select.Option key={"3rd Year"} value={3}>
                  3rd Year
                </Select.Option>
                <Select.Option key={"4th Year"} value={4}>
                  4th Year
                </Select.Option>
              </Select>
            </div>
          </div>
          <div className="flex flex-col gap-2 border-r-2 border-gray-200 rounded pr-2">
            <div className="text-md font-bold text-primary">
              Miscellaneous Tags
            </div>
            <div className="flex gap-2 items-center justify-between">
              <div className="text-md font-semibold text-gray-600">
                Enter Tag
              </div>
              <Input
                disabled={
                  form.entity !== "" && form.category !== "" ? false : true
                }
                placeholder="Enter Tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                className="w-1/2"
              />
            </div>
            <div className="flex gap-2 items-center justify-between">
              <div className="text-md font-semibold text-gray-600">Add Tag</div>
              <button
                disabled={
                  form.entity !== "" && form.category !== "" ? false : true
                }
                className="bg-rose-500 text-white p-1 px-4 rounded"
                onClick={() => {
                  AddMiscTag(tag);
                  setTag("");
                }}
              >
                Add
              </button>
            </div>
            {form.miscTags && form.miscTags.length > 0 && (
              <div className="flex gap-2 items-center justify-between">
                <div className="flex gap-2  flex-wrap">
                  {form.miscTags.map((tag: any, index: any) => (
                    <div
                      className="flex bg-rose-200 p-1 px-4 rounded items-center"
                      key={index}
                    >
                      {tag}
                      <div
                        className="ml-2 font-sm rounded-full text-black cursor-pointer"
                        onClick={() => {
                          const newTags = form.miscTags.filter(
                            (item: any) => item !== tag
                          );
                          setForm({ ...form, miscTags: newTags });
                        }}
                      >
                        x
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-col flex-wrap align-middle gap-2">
          <QuestionTextContainer
            form={form}
            setForm={setForm}
            modules={modules}
          />
          <HintsView hints={form.hints} setForm={setForm} form={form} />
          {form.hints && form.hints.length > 0 && (
            <HighlightedHints
              questionText={form?.questionText}
              hints={form.hints}
            />
          )}
        </div>

        <ImageUpload form={form} setForm={setForm} />
        <Divider />
        <QuestionTip form={form} setForm={setForm} />
      </div>
    </>
  );
}

function Divider() {
  return <div className="border-t border-gray-200 my-4 border-1"></div>;
}
