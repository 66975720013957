import { Flex, Image, Table, Typography, type TableProps } from "antd";
import { ExpertTableInterface } from "./types";

export const AdminManagerExpertColumn: TableProps<ExpertTableInterface>["columns"] = [
  // Table.EXPAND_COLUMN,
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    render: (tags: string[]) => {
      return (
        <Flex className="flex gap-2 items-center">
          {tags?.map((tag: string, i) => (
            <Typography.Text
              className="border-2 border-red-500 text-gray-800 font-medium px-2.5 py-2 rounded-md flex items-center justify-center text-base"
              key={tag + i}
            >
              {tag}
            </Typography.Text>
          ))}
        </Flex>
      );
    },
  },
  // Table.SELECTION_COLUMN,
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    width: "250px",
    ellipsis: true,
  },
  {
    title: "Image",
    dataIndex: "img",
    key: "img",
    width: "350px",
    render: (img: string) => <Image src={img} alt="expert-img" width={40} height={40} />,
  },
];
