import { useEffect, useState } from "react";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { InfoIcon } from "../../../shared-components/ui/Icons";

import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";

import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Input, Label } from "../../../shared-components/ui/CustomForm";
import { CouponModalProps } from "../interfaces/latest-commission-interface";
import {
  formatNumberWithCommas,
  getTeamLength,
  getUserIdField,
  renderChips,
} from "../utils";
import ReimbursementTable from "./components/reimbursement-table";
import NestedOrders from "./components/view-nested-orders";
import ViewSalesTable from "./components/view-sales-table";
import CustomBadges from "shared-components/ui/CustomBadges";
import TargetModal from "./components/TargetModal";
import TargetManagement from "./components/Target-Management";
import ViewUserTargetTable from "./components/view-user-target-table";

const ViewUserDetail = () => {
  const [selectStatus, setSelectStatus] = useState("Active");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [user, setUser] = useState(null);
  const [userCompensationDetail, setUserCompensationDetail] = useState(null);
  const [reimbursements, setReimbursements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const id = params?.id;
  const [nestedStructure, setNestedStructure] = useState([]);
  const [displayScreen, setDisplayScreen] = useState<string>("Reimbursement");
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    fetchData();
  }, [refreshData, id]);

  async function fetchData() {
    setIsLoading(true);
    console.log("User Id: ", id);
    try {
      const response = await CommissionAPI("getCommissionUserById", [id]);

      if (response) {
        const fetchedUser = response?.data?.data?.user;
        const fetchedCompensation = response?.data?.data?.compensationUser;
        const fetchedReimbursements = response?.data?.data?.reimbursements;
        const fetchedNestedStructure = response?.data?.data?.nestedStructure;
        const fetchedCoupons = response?.data?.data?.coupons;
        setReimbursements(fetchedReimbursements);
        setUser(fetchedUser);
        setUserCompensationDetail(fetchedCompensation);
        setNestedStructure(fetchedNestedStructure);
        setCoupons(fetchedCoupons);
        console.log("Response: ", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const initialModalState: {
    isOpen: boolean;
    data: any;
  } = {
    isOpen: false,
    data: null,
  };

  const [couponDetailsModal, setCouponDetailsModal] = useState<{
    isOpen: boolean;
    data: any;
  }>(initialModalState);
  console.log("User: ", user);
  return (
    <>
      <Toaster />
      <CouponModal
        modal={couponDetailsModal}
        setModal={setCouponDetailsModal}
        initialModalState={initialModalState}
      />

      {/* =========================== */}
      {/* =========================== */}
      {/*  ReimbursementForm Modal*/}
      <ReimbursementForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={user}
        compensationDetails={userCompensationDetail}
      />
      {/* =========================== */}
      {/* =========================== */}
      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <div className="">
            <div className="mb-6">
              <CustomBadges
                label={`CODE: ${coupons[0]?.couponCode}`}
                color="success"
                showIcon
                textSize="lg"
              />
            </div>
            {user && (
              <h2 className="text-2xl font-bold text-gray-700 flex items-center space-x-3">
                <span className=""> {user?.name}</span>{" "}
                {renderChips(user?.role)}
              </h2>
            )}
          </div>
          <ButtonOutlined
            handleClick={() => {
              setRefreshData((prev) => prev + 1);
            }}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                {/* <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setAllUsers={setAllUsers}
                  adminUsers={adminUsers}
                /> */}
                <div className="flex items-center p-4 space-x-3">
                  <CustomDropDown
                    label="View"
                    options={[
                      "All Sales",
                      "My Sales",
                      "Reimbursement",
                      "Hierarchy Order View",
                      "Target Management",
                      "My Target",
                    ]}
                    onSelect={(selectedStatus) =>
                      // handleSelectStatusChange(selectedStatus.toString())
                      setDisplayScreen(selectedStatus)
                    }
                    width="min-w-[12rem]"
                    value={displayScreen}
                  />
                </div>
                <div className="flex items-center gap-6 my-10 mx-10">
                  <div className="p-5 shadow-lg rounded-md text-center">
                    <h1 className="font-semibold">Total Amount Earned</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.totalAmountEarned?.toFixed(2)}
                    </p>
                  </div>
                  <div className="p-5 shadow-lg rounded-md text-center">
                    <h1 className="font-semibold">Pending Balance</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.pendingBalance?.toFixed(2)}
                    </p>
                  </div>
                  <div className="p-5 shadow-lg rounded-md text-center">
                    <h1 className="font-semibold">Paid Amount</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.paidAmount?.toFixed(2)}
                    </p>
                  </div>
                  <div
                    className=" shadow-lg rounded-md  text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium  text-sm p-5 text-center me-2 mb-2 cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <h1 className="font-semibold">Request Reimbursement</h1>
                  </div>
                </div>
                {displayScreen === "All Sales" && (
                  <ViewSalesTable
                    orders={user?.orders}
                    setCouponDetailsModal={setCouponDetailsModal}
                  />
                )}
                {displayScreen === "My Sales" && (
                  <ViewSalesTable
                    orders={user?.orders}
                    setCouponDetailsModal={setCouponDetailsModal}
                    showMySales
                  />
                )}
                {displayScreen === "Reimbursement" && (
                  <>
                    <ReimbursementTable reimbursements={reimbursements} />
                  </>
                )}
                {displayScreen === "Target Management" && (
                  <>
                    <TargetManagement
                      supervisor={user}
                      screen="Target Management"
                    />
                  </>
                )}
                {displayScreen === "My Target" && (
                  <>
                    <TargetManagement supervisor={user} screen="My Target" />
                  </>
                )}
                {displayScreen === "Hierarchy Order View" && (
                  <NestedOrders
                    users={nestedStructure}
                    level={0}
                    showOrders
                    setCouponDetailsModal={setCouponDetailsModal}
                  />
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

// export const TableTop = ({
//   searchQuery,
//   handleSearchQueryChange,
//   setAllUsers,
//   adminUsers,
// }) => {
//   const [openCreateDisciplineModal, setDisciplineModal] = useState(false);

//   return (
//     <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
//       <div className="w-full md:w-1/2">
//         <form className="flex items-center">
//           <label htmlFor="simple-search" className="sr-only">
//             Search
//           </label>
//           <div className="relative w-full">
//             <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
//               <SearchIcon color="text-gray-400" />
//             </div>
//             <input
//               type="text"
//               id="simple-search"
//               className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
//               placeholder="Search"
//               required={true}
//               value={searchQuery}
//               onChange={handleSearchQueryChange}
//             />
//           </div>
//         </form>
//       </div>
//       <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
//         <ButtonFill
//           handleClick={() => {
//             setDisciplineModal(true);
//           }}
//         >
//           Add new user
//         </ButtonFill>
//       </div>
//       <CommissionUserForm
//         mode="add"
//         active={openCreateDisciplineModal}
//         setModal={setDisciplineModal}
//         onCancel={() => {
//           setDisciplineModal(false);
//         }}
//         onConfirm={() => {
//           setDisciplineModal(false);
//         }}
//         setAllUsers={setAllUsers}
//         reportToUsers={adminUsers}
//       />
//     </div>
//   );
// };

export const ReimbursementForm = (props) => {
  const minimumAmountUserCanRequest = 5000;
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [requestAmountType, setRequestAmountType] = useState(
    "Total Pending Balance"
  );
  const [isLoading, setIsLoading] = useState(false);

  const resetForm = () => {
    setRequestedAmount(0);
    setPendingAmount(0);
    setRequestAmountType("Total Pending Balance");
    props.setIsModalOpen(false);
  };
  useEffect(() => {
    if (props?.compensationDetails) {
      const pendingAmount = props?.compensationDetails?.pendingBalance || 0;
      setPendingAmount(pendingAmount);
      setRequestedAmount(pendingAmount);
    }
  }, [props?.compensationDetails]);

  const handleInputChange = (name, value) => {
    // if (value <= pendingAmount && value >= minimumAmountUserCanRequest) {
    setRequestedAmount(value);
    // }
  };

  const closeModal = () => {
    resetForm();
  };

  const submit = async (e) => {
    e.preventDefault();
    console.log("Inside On submit: ", requestedAmount);
    if (!requestedAmount || requestedAmount <= 0) {
      toast.error("Invalid Amount");
      return;
    }
    setIsLoading(true);

    try {
      const data = {
        userId: props?.user?.userId,
        requestedAmount: requestedAmount,
        email: props?.user?.email,
        name: props?.user?.name,
        role: props?.user?.role,
      };
      console.log("data: ", data);
      const response = await CommissionAPI("createReimbursement", [], data);

      console.log("createReimbursement Response: ", response);
      if (response?.data?.isSuccess) {
        toast.success("Submit Successfully.");
        resetForm();
      }
    } catch (error) {
      console.error("Error createReimbursement:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="deleteModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.isModalOpen ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        {/* Modal content */}
        <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
          <button
            type="button"
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
            data-modal-toggle="deleteModal"
            onClick={() => closeModal()}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <p className="mb-4 text-gray-500 text-xl ddark:text-gray-300">
            Reimbursement Form
          </p>

          <form
            onSubmit={(e) => submit(e)}
            className="text-left my-5 space-y-5"
          >
            <CustomDropDown
              label="Select Request Type"
              options={[`Total Pending Balance`, "Custom Amount"]}
              onSelect={(type) => {
                setRequestAmountType(type);
                if (type === "Total Pending Balance") {
                  setRequestedAmount(pendingAmount);
                }
              }}
              width="w-full"
              value={requestAmountType}
              isError={false}
              errorMessage={""}
            />
            {requestAmountType === "Custom Amount" ? (
              <div className="space-y-2">
                <Label>Enter Amount</Label>
                <Input
                  type="number"
                  value={requestedAmount}
                  name="requestedAmount"
                  onChange={handleInputChange}
                  isError={false}
                  errorMessage={""}
                />
              </div>
            ) : null}

            <p className="mb-4 text-gray-700 text-center font-semibold text-lg ddark:text-gray-300">
              Amount : {formatNumberWithCommas(requestedAmount)}
            </p>
            <div className="flex justify-center items-center space-x-4">
              <ButtonOutlined handleClick={() => closeModal()}>
                cancel
              </ButtonOutlined>

              <ButtonFill type="Submit" disabled={isLoading}>
                {isLoading ? "Loading.." : " Submit"}
              </ButtonFill>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const CouponModal = ({
  modal,
  setModal,
  initialModalState,
}: CouponModalProps) => {
  console.log("modal:", modal);
  return (
    <>
      <div
        id="deleteModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          modal.isOpen ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
              data-modal-toggle="deleteModal"
              onClick={() => setModal(initialModalState)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            {modal?.data ? (
              <>
                <div className="text-left">
                  <p className="mb-4 text-gray-500 ddark:text-gray-300">
                    Coupon Details:
                  </p>

                  <div className="flex  space-x-2">
                    <span className="font-semibold">Coupon Code:</span>
                    <span className="">{modal?.data?.code}</span>
                  </div>
                  <div className="flexx  space-y-2">
                    <span className="font-semibold inline-block">
                      Purchased User:
                    </span>
                    <span className="">{modal?.data?.purchasedUserEmail}</span>
                  </div>
                  <div className="space-y-2">
                    <span className="font-semibold inline-block">
                      Coupon User :
                    </span>
                    <span className="">{modal?.data?.couponUser?.email}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-semibold">Coupon User Role:</span>
                    <span className="">
                      {renderChips(modal?.data?.couponUser?.role)}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <p className=" text-gray-700 ddark:text-gray-300">
                No Coupon Details Available
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewUserDetail;
