import React, { useEffect, useState } from "react";
import { CommissionBanner } from "../utils";
import CouponModal from "./components/coupon-modal";
import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import ViewCoupons from "./components/ViewCoupons";
import { Toaster } from "react-hot-toast";

const CouponManager = () => {
  const [refreshData, setRefreshData] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  async function fetchData() {
    setIsLoading(true);
    try {
      const [commissionUsersResponse] = await Promise.all([
        CommissionAPI("getAllCommissionUsers"),
      ]);

      if (commissionUsersResponse.status === 200) {
        const commissionUsersData = commissionUsersResponse?.data?.data || [];
        setAllUsers(commissionUsersData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchCoupons() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getAllCoupons");
      console.log("getAllCoupons: ", response);
      if (response?.data?.success) {
        const data = response?.data?.data;
        console.log("getAllCoupons Data: ", data);
        setCoupons(data);
      }
    } catch (error) {
      console.error("Error fetching reimbursements:", error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchCoupons();
  }, [refreshData]);

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />
      <CommissionBanner
        title="Coupon Manager"
        setRefreshData={setRefreshData}
      />
      <CouponModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        allUsers={allUsers}
      />
      <ViewCoupons
        allUsers={allUsers}
        coupons={coupons}
        setCoupons={setCoupons}
      />
      <section className="my-5 antialiased">
        <div className="mx-auto"></div>
      </section>
    </div>
  );
};

export default CouponManager;
