import { BasicGetApi } from "../../../(apis)/(shared-apis)/basic-get-apis";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import ViewCommissionUser from "../components/ViewCommissionUser";
import {
  CommissionBanner,
  getUsersOfSpecificRole,
  ROlES,
  TableTop,
} from "../utils";

const ManageManagers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [nestedStructure, setNestedStructure] = useState([]);
  const [allCommissionUsers, setAllCommissionUsers] = useState([]);
  const [allAdminUsers, setAllAdminUsers] = useState([]);

  useEffect(() => {
    fetchData();
  }, [refreshData]);

  async function fetchData() {
    setIsLoading(true);

    try {
      const [adminUsersResponse, commissionUsersResponse, hierarchyResponse] =
        await Promise.all([
          BasicGetApi("getUserInfo"),
          CommissionAPI("getAllCommissionUsers"),
          CommissionAPI("getHierarchy", [ROlES.MANAGER]),
        ]);

      if (adminUsersResponse.status === 200) {
        const adminUsersData = adminUsersResponse?.data?.data || [];
        console.log("Admin Users Response:", adminUsersResponse);
        setAllAdminUsers(adminUsersData);
      }

      if (commissionUsersResponse.status === 200) {
        const commissionUsersData = commissionUsersResponse?.data?.data || [];
        const filteredUsers = getUsersOfSpecificRole(
          commissionUsersData,
          ROlES.MANAGER
        );
        console.log("commissionUsersResponse", commissionUsersResponse);
        setAllCommissionUsers(commissionUsersData);
        setAllUsers(filteredUsers);
      }

      if (hierarchyResponse.status === 200) {
        const hierarchyData = hierarchyResponse?.data?.data || [];
        console.log("hierarchyData: ", hierarchyData);
        setNestedStructure(hierarchyData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    // if (e.target.value === "") {
    //   setAllUsers(allDisciplines);
    //   return;
    // }
  };

  return (
    <>
      <div className="w-full pb-20 px-5">
        <CommissionBanner
          title="Manage Managers"
          setRefreshData={setRefreshData}
        />

        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setAllUsers={setAllUsers}
                  adminUsers={allAdminUsers}
                  allCommissionUsers={allCommissionUsers}
                  allAdminUsers={allAdminUsers}
                  label={ROlES.MANAGER}
                  reportToUsers={undefined}
                />

                <ViewCommissionUser
                  allUsers={allUsers}
                  setAllUsers={setAllUsers}
                  allAdminUsers={allAdminUsers}
                  label={ROlES.MANAGER}
                  nestedStructure={nestedStructure}
                  adminUsers={undefined}
                  reportToUsers={undefined}
                  allCommissionUsers={undefined}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default ManageManagers;
