import React, { useState } from "react";
import CustomDropDown from "shared-components/ui/CustomDropDown";

const ViewUserTargetTable = ({ allUsers }) => {
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("All Roles");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isActiveFilter, setIsActiveFilter] = useState("Active");

  // Filter logic based on selected filters
  const filteredUsers = allUsers.filter((user) => {
    const matchesEmail = user.email
      .toLowerCase()
      .includes(searchEmail.toLowerCase());
    const matchesRole =
      selectedRole === "All Roles" || user.role === selectedRole;

    const matchesMonth =
      selectedMonth === "" ||
      user.referralTargets.some(
        (target) =>
          new Date(target.startDate).getMonth() + 1 === parseInt(selectedMonth)
      );

    return matchesEmail && matchesRole && matchesMonth;
  });

  return (
    <div>
      {/* Filter Inputs */}
      <div className="flex space-x-4 mb-4">
        <input
          type="text"
          placeholder="Search by Email"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
          className="border px-2 py-1 rounded"
        />

        <CustomDropDown
          label="Role"
          options={["All Roles", "ASSOCIATE", "MANAGER"]}
          onSelect={(role) => setSelectedRole(role)}
          width="min-w-[12rem]"
          value={selectedRole}
          noLabel
        />

        <input
          type="month"
          placeholder="Filter by Month"
          onChange={(e) => setSelectedMonth(e.target.value.split("-")[1])}
          className="border px-2 py-1 rounded"
        />

        <CustomDropDown
          label="Active Status"
          options={["All", "Active", "Inactive"]}
          onSelect={(status) => setIsActiveFilter(status)}
          width="min-w-[12rem]"
          value={isActiveFilter}
          noLabel
        />
      </div>

      {/* User Target Table */}
      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-4 py-2 border-b">User Name</th>
              <th className="px-4 py-2 border-b">Email</th>
              <th className="px-4 py-2 border-b">Role</th>
              <th className="px-4 py-2 border-b">Target</th>
              <th className="px-4 py-2 border-b">Cash Prize</th>
              <th className="px-4 py-2 border-b">Total Target Done</th>
              <th className="px-4 py-2 border-b">Start Date</th>
              <th className="px-4 py-2 border-b">End Date</th>
              <th className="px-4 py-2 border-b">Is Active</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers && filteredUsers.length > 0 ? (
              filteredUsers.map((user: any) =>
                user.referralTargets
                  // Filter referral targets based on isActiveFilter
                  .filter((target: any) => {
                    if (isActiveFilter === "All") {
                      return true; // Include all if filter is set to "All"
                    }
                    return (
                      (isActiveFilter === "Active" && target?.isActive) ||
                      (isActiveFilter === "Inactive" && !target?.isActive)
                    );
                  })
                  // Map over filtered targets
                  .map((target) => (
                    <tr key={target._id}>
                      <td className="px-4 py-2 border-b">{user?.name}</td>
                      <td className="px-4 py-2 border-b">{user?.email}</td>
                      <td className="px-4 py-2 border-b">{user?.role}</td>
                      <td className="px-4 py-2 border-b">{target?.target}</td>
                      <td className="px-4 py-2 border-b">
                        {target?.cashPrize}
                      </td>
                      <td className="px-4 py-2 border-b">
                        {target?.totalTargetDone}
                      </td>
                      <td className="px-4 py-2 border-b">
                        {new Date(target?.startDate).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-2 border-b">
                        {target.endDate
                          ? new Date(target?.endDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td className="px-4 py-2 border-b">
                        {target?.isActive ? "Yes" : "No"}
                      </td>
                    </tr>
                  ))
              )
            ) : (
              <tr>
                <td colSpan={9} className="px-4 py-2 text-center">
                  No matching results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewUserTargetTable;
