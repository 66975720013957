import React, { useContext } from "react";
import { DoubtDisplayType } from "../pages/experts/types";
import Loading from "../../../shared-components/ui/Loading";
// @ts-ignore   TODO:FIX LATER
import Back from "./Back.png";
// @ts-ignore   TODO:FIX LATER
import styles from "./styles.module.css";
import { Image, message } from "antd";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { UserContext } from "state-management/user-context";

const DoubtQuestion: React.FC<DoubtDisplayType> = ({
  DoubtDisplay,
  setDoubtDisplay,

  setViewDoubt,
}) => {
  const history = useNavigate();
  const { user } = useContext(UserContext);
  const TakeDoubt = async (id: string) => {
    const username = user.username;
    if (username === "" || !username) {
      message.error("Please login to take doubt")
      return;
    }

    // const response = await fetch(`${backendUrl}/TakeDoubt`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ id: id, username: username }),
    // });

    const { data } = await apiRequest("TakeDoubt", { id, username });
    console.log("takedoubt data", data);
    if (data.success) {
      message.error( `${data?.message}`)
      setTimeout(() => {
        history("/VideoInterface", { state: { Doubt: DoubtDisplay } });
      }, 2000);
      // fetchDoubts();
    } else {
      message.error(`${data.message}`)
    }
  };

  return !DoubtDisplay ? (
    <Loading />
  ) : (
    <>
      <div className="container my-5" id={styles.Details}>
        <div className="mb-3 py-2 flex items-center">
          <img
            src={"/Back.png"}
            alt="Back"
            width={25}
            height={25}
            onClick={() => {
              setViewDoubt(null);
              setDoubtDisplay(null);
            }}
            className="cursor-pointer"
          />
          <h4>Question Details</h4>
        </div>
      </div>

      <div className="container " id={styles.Description}>
        {DoubtDisplay.resource && (
          <div>
            <h5>
              <span>Resource:</span> {DoubtDisplay.resource}
            </h5>
          </div>
        )}
        {DoubtDisplay.subject && (
          <div>
            <h5>
              <span>Subject:</span> {DoubtDisplay.subject}
            </h5>
          </div>
        )}
        {DoubtDisplay.topic && (
          <div>
            <h5>
              <span>Topic:</span> {DoubtDisplay.topic}
            </h5>
          </div>
        )}
      </div>

      <div className="container my-3" id={styles.Description}>
        <h5>
          <span>Question Details:</span> {DoubtDisplay.description}
        </h5>
      </div>
      {DoubtDisplay.img && (
        <div className="container my-3" id={styles.Image}>
          <Image
            src={DoubtDisplay.img}
            alt="Doubt Image"
            className="rounded-md"
          />
        </div>
      )}

      <div className={`container ${styles.ButtonHolder}`}>
        <button
          className={styles.ButtonBack}
          onClick={() => {
            setViewDoubt(null);
            setDoubtDisplay(null);
          }}
        >
          Back
        </button>
        <button
          className={styles.ButtonNew}
          onClick={() => {
            TakeDoubt(DoubtDisplay._id);
          }}
        >
          Solve Doubt
        </button>
      </div>
    </>
  );
};

export default DoubtQuestion;
