import { Image, message, notification } from "antd";
import { useContext, useEffect, useState } from "react";
// @ts-ignore
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { UserContext } from "state-management/user-context";
import ExpertTable from "./Table";
import { BackIcon } from "shared-components/ui/Icons";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
type Statistics = {
  Pendingcount: number;
  Solvedcount: number;
  UserRating: number;
  UserCoins: number;
};

type Doubt = {
  _id: string;
  resource?: string;
  subject?: string;
  topic?: string;
  description: string;
  questionID?: string;
  ExpireDate?: string;
  img?: any;
};

export default function ExpertDashboard() {
  const { user } = useContext(UserContext);
  const [statistics, setStatistics] = useState<Statistics | undefined>();
  const [doubts, setDoubts] = useState<Doubt[]>([]);
  const [viewDoubt, setViewDoubt] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetchingImage, setIsFetchingImage] = useState<boolean>(false);
  const [doubtDisplay, setDoubtDisplay] = useState<Doubt | undefined>();
  const [image, setImage] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [uCoins, setCoins] = useState<number>(0);

  const navigate = useNavigate();

  const getUserStats = async () => {
    if (!user || !user.username) {
      return;
    }
    const response = await apiRequest("ExpertStatistics", {
      username: user?.username,
    });
    const data = response.data;
    setStatistics(data);
    setRating(parseFloat(data?.UserRating?.toFixed(2) || 0.0));
    setCoins(data.UserCoins);
  };

  const fetchDoubts = async () => {
    try {
      setLoading(true);
      if (!user || !user.username) {
        return;
      }
      const response = await apiRequest("GetExpertsPendingDoubts", {
        username: user?.username,
      });
      const data = response.data;
      if (data?.length > 0) {
        setDoubts(data);
      } else {
        message.warning("No pending doubts found");
        setDoubts([]);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDoubts();
    getUserStats();
  }, [user]);

  const getImage = async (id: string) => {
  try {
    setIsFetchingImage(true)
    const response = await apiRequest("GetImage", {
      id,
    });
    const data = response.data;
    if(data){
      setDoubtDisplay(data);
      setImage(data.img);

    }else{
      message.error("Image not found");
    }
 
  } catch (error) {
    message.error(error.message);
  }finally{
    setIsFetchingImage(false)
  }
  };

  const handleViewDoubt = (id: string) => {
    setViewDoubt(true);
    getImage(id);
  };

  const takeDoubt = async () => {
    const username = user?.username;
    if (!username) {
      notification.error({
        message: "Login to take doubt",
        description: "Please login to take doubt",
      });
      return;
    }

    navigate("/VideoInterface", { state: { Doubt: doubtDisplay } });
  };

  const unTakeDoubt = async (id: string) => {
    const response = await apiRequest("UnTakeDoubt", {
      id,
    });
    const data = response.data;
    if (data.success) {
      notification.success({
        message: "Doubt Returned",
        description: "Doubt has been returned to the pool",
      });
      setViewDoubt(false);
      setDoubtDisplay(undefined);
      setImage(null);
      fetchDoubts();
    } else {
      notification.error({
        message: "Error",
        description: "Error in returning doubt",
      });
    }
  };

  return (
    <>
  { !viewDoubt &&   <div className={styles.Navigation}>
        <h4>Experts Solution</h4>
      </div>}

      {!viewDoubt && (
        <>
          <div className={styles.Center}>
            <div className={styles.LogoDisplay}>
              <div className={`container ${styles.Logo}`}>
                <img src={"/DoubtLogo.png"} alt="Expert Solve" width={100} />
                <div className={styles.Headings}>
                  <h4>
                    Expert <span>Solution</span>
                  </h4>
                  <h6>
                    Get top-notch video solution answers to your MDCAT questions
                  </h6>
                  <h6>from top-merit experts 🙌🏻</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="container my-4">
            <div className={styles.Bar}>
              <h5 className="text-left">My Questions</h5>
              <button>
                <Link className={styles.Link} to="/Experts">
                  Back
                </Link>
              </button>
            </div>

            <div className={styles.StatisticsHolder}>
              <div className={styles.Statistics}>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Pending Questions</h6>
                  <h6>{statistics?.Pendingcount}</h6>
                </div>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Answered Questions</h6>
                  <h6>{statistics?.Solvedcount}</h6>
                </div>
              </div>
              <div className={styles.Statistics}>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Coins Earned</h6>
                  <div className={styles.StatsInner}>
                    <h6>{uCoins}</h6>
                    <img src={"/Coin.png"} width={20} height={20} alt="Coins" />
                  </div>
                </div>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Rating</h6>
                  <div className={styles.StatsInner}>
                    <h6>{rating}</h6>
                    <img src={"/Star.png"} width={20} height={20} alt="Star" />
                  </div>
                </div>
              </div>
            </div>

            <ExpertTable
              doubts={doubts}
              handleViewDoubt={handleViewDoubt}
              loading={loading}
            />
          </div>
        </>
      )}

      {viewDoubt && (
        <div className="container !mb-20">
          <Loader loading={isFetchingImage}/>
          <div className={'flex gap-1'}>
          <button
              onClick={() => {
                setViewDoubt(false);
                setImage(null);
              }}
            >
              <BackIcon/>
            </button>
            <h5 className="text-left text-xl">View Doubt</h5>
         
          </div>
          <div className={'flex flex-col gap-4'}>
            <div className={'flex flex-col gap-4'}>
              <h4 className="p-4 shadow-sm border rounded-sm"><strong>Description:</strong> {doubtDisplay?.description}</h4>

              <div  className="p-4 shadow-sm border rounded-sm">
              <h1 className="text-xl font-semibold">Image : </h1>
              {image && <Image className="min-w-screen !h-96" src={image} alt="Doubt Image" />}

              </div>
            </div>
            <div className={'pb-20 flex justify-end gap-4'}>
              <CustomButton   text="Take Doubt"  onClick={() => {
                  takeDoubt();
                }}/>
              <CustomButton   text="  Return Doubt"     onClick={() => {
                  unTakeDoubt(doubtDisplay?._id || "");
                }}/>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
