import { Button, Image, Table } from "antd";
import { useMemo } from "react";
//  @ts-ignore
import styles from "./styles.module.css";
import GlobalTable from "shared-components/table/GlobalTable";
import { EyeIcon } from "shared-components/ui/Icons";
import dayjs from 'dayjs'
const ExpertTable = ({ handleViewDoubt, doubts, loading }) => {
  // Define your columns structure
  const columns = useMemo(
    () => [
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        render: (_, doubt) => {
            console.log("doubt", doubt)
            return (
                <div className="flex gap-1">
                  {doubt?.resource && <div id={styles.Tag}>{doubt?.resource}</div>}
                  {doubt?.subject && <div id={styles.Tag}>{doubt?.subject}</div>}
                  {doubt?.topic && <div id={styles.Tag}>{doubt?.topic}</div>}
                </div>
              )
        },
      },
    //   {
    //     title: "Source",
    //     dataIndex: "source",
    //     key: "source",
    //     render: (text, doubt) =>
    //       doubt?.questionID ? (
    //         <div id={styles.Source}>Premed QBank</div>
    //       ) : (
    //         <div id={styles.Source2}>Own Question</div>
    //       ),
    //   },
    {
        title: "Image",
        dataIndex: "img",
        key: "img",
      
        render: (img: string) => <Image src={img} alt="expert-img" width={40} height={40} />,
      },
 
      {
        title: "Expires At",
        dataIndex: "ExpireDate",
        key: "expireDate",
        render: (text, doubt) =>
          doubt?.ExpireDate
            ? dayjs(doubt?.ExpireDate).format('DD/MM/YYYY, hh:mm a') 
            : "N/A",
      },
      {
        title: "View",
        key: "action",
        render: (text, doubt) => (
          <div onClick={() => handleViewDoubt(doubt?._id)}>
            <EyeIcon />
          </div>
        ),
      },
    ],
    [handleViewDoubt]
  );

  // Render Ant Design Table
  return <GlobalTable columns={columns} data={doubts} loading={loading} />;
};

export default ExpertTable;
