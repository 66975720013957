import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { useReactMediaRecorder } from "react-media-recorder";
// @ts-ignore
import Background from "./Background.png";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { useParams } from "react-router-dom";
import { useTimer } from 'react-timer-hook'; // Import the timer hook
import { Modal } from "antd";

export default function ExpertInterface() {
    const [penColor, setPenColor] = useState("#000000");
    const [penWidth, setPenWidth] = useState(2);
    const [isRecording, setIsRecording] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
    const { id } = useParams();
    const [DoubtGet, setDoubtGet] = useState<any>();
    const [showDoubt, setShowDoubt] = useState(false);
    const timerDuration = 180; // Duration in seconds
    const [showModal, setShowModal] = useState(false); // New state for modal visibility

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start, // This should be called only when starting the recording
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp: new Date(Date.now() + timerDuration * 1000), autoStart: false, onExpire: () => console.warn('onExpire called') });

    useEffect(() => {
        const Check = async () => {
            if (id) {
                const response = await apiRequest("GetSubjectiveDoubt", {}, [id]);
                const data = response.data;
                setDoubtGet(data?.Details);
            }
        };

        Check();
    }, [id]);


    useEffect(() => {
        if (svgRef.current) {
            const svg = d3.select(svgRef.current);
            const width = svg.node()?.clientWidth || 800;
            const height = svg.node()?.clientHeight || 600;

            svg.attr("width", width).attr("height", height);

            const handleMouseDown = (event: any) => {
                setIsDrawing(true);
                const [x, y] = d3.pointer(event);
                svg.append("path")
                    .attr("stroke", penColor)
                    .attr("stroke-width", penWidth)
                    .attr("fill", "none")
                    .attr("d", `M${x},${y}`);
            };

            const handleMouseMove = (event: any) => {
                if (!isDrawing) return;
                const [x, y] = d3.pointer(event);
                const activePath = svg.select("path:last-child");
                const d = activePath.attr("d");
                activePath.attr("d", `${d} L${x},${y}`);
            };

            const handleMouseUp = () => {
                setIsDrawing(false);
            };

            svg.on("mousedown touchstart", handleMouseDown);
            svg.on("mousemove touchmove", handleMouseMove);
            svg.on("mouseup touchend", handleMouseUp);
            svg.on("mouseleave touchcancel", handleMouseUp);

            return () => {
                svg.on("mousedown touchstart", null);
                svg.on("mousemove touchmove", null);
                svg.on("mouseup touchend", null);
                svg.on("mouseleave touchcancel", null);
            };
        }
    }, [penColor, penWidth, isDrawing]);

    useEffect(() => {
        // // Initialize camera
        // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        //     navigator.mediaDevices.getUserMedia({ video: true })
        //         .then((stream) => {
        //             if (videoRef.current) {
        //                 videoRef.current.srcObject = stream;
        //             }
        //         })
        //         .catch((error) => {
        //             console.error("Error accessing camera:", error);
        //         });
        // }
    }, []);

    const { status, startRecording: startMediaRecording, stopRecording: stopMediaRecording, mediaBlobUrl } = useReactMediaRecorder({
        screen: true,
        audio: true,
        video: true,
        onStop: (blobUrl, blob) => {
            // Handle recording stop if needed
        },
    });

    const startRecording = async () => {
        if (isRecording) return; // Prevent starting a new recording if already recording
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
            setScreenStream(stream);
            startMediaRecording();
            setIsRecording(true);
            
            // Removed the start() call here to prevent auto-start
        } catch (error) {
            console.error("Error starting screen sharing:", error);
        }
    };

    const stopRecording = () => {
        stopMediaRecording();
        setIsRecording(false);
        pause();
        if (screenStream) {
            screenStream.getTracks().forEach(track => track.stop());
            setScreenStream(null);
        }
    };

    const downloadVideo = () => {
        if (mediaBlobUrl) {
            const a = document.createElement("a");
            a.href = mediaBlobUrl;
            a.download = "recording.mp4";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const clearCanvas = () => {
        if (svgRef.current) {
            d3.select(svgRef.current).selectAll("path").remove();
        }
    };

    const [uploadedBlob, setUploadedBlob] = useState(null);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const blob = new Blob([reader.result], { type: file.type });
            setUploadedVideo(file);
            setUploadedBlob(blob);
        };

        reader.readAsArrayBuffer(file);
    };

    // Initialize timer only when recording starts
    useEffect(() => {
        if (isRecording) {
            const time = new Date();
            time.setSeconds(time.getSeconds() + timerDuration); // 3 minutes time
            restart(time); // Restart timer with new expiry time
        }
    }, [isRecording]);

    // New effect to handle timer expiration
    useEffect(() => {
        if (totalSeconds <= 0) {
            stopRecording(); // Stop recording when time is up
            setShowModal(true); // Show modal to re-record or submit
        }
    }, [totalSeconds]);

    const handleReRecord = () => {
        setShowModal(false);
        startRecording(); // Restart recording
    };

    const handleSubmit = () => {
        setShowModal(false);
        // Handle submission logic here
    };

    return (
        <div className="flex h-screen">
            <div className="flex-grow relative">
                <svg ref={svgRef} className="w-full h-[200vh] border border-gray-300"
                    style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: "100% 100vh",
                        display: "flex",
                        flexDirection: "column",
                    }}
                ></svg>

                <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
                    {/* <video ref={videoRef} className="w-full h-full object-cover" autoPlay muted></video> */}
                    <div className="flex flex-row items-center justify-center bg-white rounded-lg shadow-lg gap-6 px-4 py-2">
                        {/*pen color */}
                        <div className="flex flex-col items-center justify-center">
                            <label className="block text-sm font-medium text-gray-700">Pen Settings</label>
                            <div className="flex flex-row items-center justify-center gap-2">
                                <input
                                    type="color"
                                    value={penColor}
                                    onChange={(e) => setPenColor(e.target.value)}
                                    className="mt-1 block w-8 h-8 border-2 border-white rounded-full"
                                />
                                {/* 5 different widths */}
                                <div className={`bg-black w-2 h-2 rounded-full ${penWidth === 1 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(1)}></div>
                                <div className={`bg-black w-3 h-3 rounded-full ${penWidth === 2 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(2)}></div>
                                <div className={`bg-black w-4 h-4 rounded-full ${penWidth === 3 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(3)}></div>
                                <div className={`bg-black w-5 h-5 rounded-full ${penWidth === 4 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(4)}></div>
                                <div className={`bg-black w-6 h-6 rounded-full ${penWidth === 5 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(5)}></div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-2">
                            <button
                                onClick={clearCanvas}
                                className="cursor-pointer bg-red-500 text-white px-2 text-sm py-1 rounded hover:bg-red-600"
                            >
                                Clear Canvas
                            </button>
                            {!isRecording && (
                                <button
                                    onClick={startRecording}
                                    className={`${isRecording ? "bg-red-500" : "bg-green-500"
                                        } text-white px-2 text-sm py-1 rounded hover:opacity-90 cursor-pointer`}
                                    disabled={isRecording}
                                >
                                    {"Start Recording"}
                                </button>
                            )}
                            {isRecording && (
                                <>
                                    <button
                                        onClick={stopRecording}
                                        className="cursor-pointer bg-red-500 text-white px-2 text-sm py-1 rounded hover:bg-red-600"
                                        disabled={!isRecording}
                                    >
                                        Stop Recording
                                    </button>
                                </>
                            )}
                            {mediaBlobUrl && (
                                <button
                                    onClick={downloadVideo}
                                    className="cursor-pointer bg-blue-500 text-white px-2 text-sm py-1 rounded hover:bg-blue-600"
                                    disabled={!mediaBlobUrl}
                                >
                                    Download Video
                                </button>
                            )}
                            <button
                                onClick={() => setShowDoubt(!showDoubt)}
                                className="cursor-pointer bg-blue-500 text-white px-2 text-sm py-1 rounded hover:bg-blue-600"
                            >
                                {showDoubt ? "Hide Doubt" : "Show Doubt"}
                            </button>
                            <input type="file" accept="video/mp4" onChange={handleVideoUpload} className="hidden" id="video-upload" />
                            <label htmlFor="video-upload" className="cursor-pointer bg-blue-500 text-white px-2 text-sm py-1 rounded hover:bg-blue-600"
                                onClick={() => document.getElementById("video-upload")?.click()}
                            >
                                Upload Video
                            </label>
                        </div>

                    </div>
                </div>

                <div className="absolute top-0 left-0">
                    {showDoubt && (
                        <div className="bg-white p-4 rounded-lg shadow-lg w-[30vw]">
                            <div className="mb-4">
                                {DoubtGet && (
                                    <h6>
                                        <span className="font-bold">Question :</span>{" "}
                                        <div
                                            dangerouslySetInnerHTML={{ __html: DoubtGet?.QuestionText }}
                                            style={{
                                                whiteSpace: "normal", // Allow text to wrap to the next line
                                                wordWrap: "break-word", // Allow breaking long words
                                                overflowWrap: "break-word", // Allow breaking mid-word when necessary
                                            }}
                                        />
                                    </h6>
                                )}
                                {DoubtGet && DoubtGet.QuestionImage && (
                                    <img
                                        src={DoubtGet?.QuestionImage}
                                        className="w-full h-auto"
                                        style={{
                                            marginRight: "1rem",
                                            marginBottom: "1rem",
                                            width: "150px",
                                            height: "150px",
                                        }}
                                    />
                                )}
                            </div>

                            <div className="mb-4">
                                {DoubtGet &&
                                    DoubtGet?.Options?.map((option) => (
                                        <div className={`container my-3 ${option.IsCorrect ? "bg-green-500" : "bg-white"} p-2 rounded-lg border border-gray-300`}>
                                            <h5
                                                id={
                                                    !option.IsCorrect
                                                        ? "bg-red-500"
                                                        : "bg-green-500"
                                                }
                                            >
                                                <span>
                                                    <b>Option: {option.OptionLetter}</b>
                                                </span>{" "}
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: option.OptionText }}
                                                />
                                            </h5>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="absolute top-0 left-0">
                    {uploadedVideo && (
                        <video src={uploadedVideo} className="w-full h-full object-cover" autoPlay muted></video>
                    )}
                </div>
                <div className="absolute top-0 right-0">
                    <div className="bg-white p-2 px-4 text-xl rounded-lg shadow-lg font-bold">
                        {minutes > 10 ? minutes : `0${minutes}`}:{seconds > 10 ? seconds : `0${seconds}`}
                    </div>
                </div>


            </div>
            {/* <div className="absolute bottom-4 left-4 w-32 h-32 rounded-full overflow-hidden border-2 border-white">
                <video ref={videoRef} className="w-full h-full object-cover" autoPlay muted></video>
            </div> */}

            {showModal && (
                <Modal
                    title="Time's Up!" // Added title for the modal
                    visible={showModal} // Control visibility with state
                    onCancel={() => setShowModal(false)} // Close modal on cancel
                    footer={null} // Set footer to null to customize
                >
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <p>Would you like to re-record or submit?</p>
                        <div className="flex justify-around mt-4">
                            <button onClick={handleReRecord} className="bg-green-500 text-white px-4 py-2 rounded">Re-record</button>
                            <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>

    );
}
