import { useState } from "react";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
import {
  formatNumberWithCommas,
  renderStatusChips,
  ROLES_OPTION,
} from "../../../../shared-pages/latest-commission/utils";
import { renderChips } from "../../../../shared-pages/latest-commission/utils";

const ReimbursementTable = ({ reimbursements }) => {
  const [selectStatus, setSelectStatus] = useState("All");
  const [selectRole, setSelectRole] = useState("All");
  const [isPromoCode, setIsPromoCode] = useState("True");

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  const filteredReimbursements = reimbursements.filter(
    (reimbursement) =>
      (selectStatus === "All" || reimbursement.status === selectStatus) &&
      (selectRole === "All" || selectRole === reimbursement?.role)
  );

  const showPromoCodeOnly = isPromoCode === "True" ? true : false;
  return (
    <>
      {" "}
      <div className="bg-white shadow-md sm:rounded-lg">
        <div className="flex items-end p-4 space-x-3">
          <CustomDropDown
            label="Status"
            options={["All", "Pending", "Approved", "Rejected"]}
            onSelect={(selectedStatus) =>
              handleSelectStatusChange(selectedStatus.toString())
            }
            width="min-w-[12rem]"
            value={selectStatus}
          />
          <CustomDropDown
            label="Role"
            options={ROLES_OPTION}
            onSelect={(selectedStatus) => setSelectRole(selectedStatus)}
            width="min-w-[12rem]"
            value={selectRole}
          />
          <CustomDropDown
            label="Promo Code"
            options={["True", "False"]}
            onSelect={(selectedStatus) => {
              setIsPromoCode(selectedStatus);
            }}
            width="min-w-[12rem]"
            value={isPromoCode}
          />
        </div>
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Name
              </th>

              <th scope="col" className="px-4 py-4">
                Status
              </th>
              <th scope="col" className="px-4 py-4">
                Role
              </th>
              {!showPromoCodeOnly ? (
                <>
                  <th scope="col" className="px-4 py-4">
                    Amount Requested
                  </th>
                </>
              ) : (
                <>
                  <th scope="col" className="px-4 py-4">
                    Promo Code
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Description
                  </th>
                </>
              )}
              <th scope="col" className="px-4 py-4">
                Paid Amount
              </th>
              <th scope="col" className="px-4 py-4">
                Approved Date
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredReimbursements &&
              filteredReimbursements?.length > 0 &&
              filteredReimbursements
                ?.filter((item: any) => {
                  return item?.isPromoCode === showPromoCodeOnly;
                })
                .map((reimbursement: any) => (
                  <tr className="border-b" key={reimbursement?._id}>
                    <td className="px-4 py-3">{reimbursement?.name}</td>

                    <td className="px-4 py-3">
                      {renderStatusChips(reimbursement?.status)}
                    </td>
                    <td className="px-4 py-3">
                      {renderChips(reimbursement.role)}
                    </td>

                    {!showPromoCodeOnly ? (
                      <>
                        <td className="px-4 py-3">
                          {formatNumberWithCommas(
                            reimbursement?.requestedAmount
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="px-4 py-3">
                          <span className="bg-indigo-100 text-indigo-800 text-sm font-semibold me-2 px-2.5 py-0.5 rounded ">
                            {reimbursement?.promoCodeDetails?.code}
                          </span>
                        </td>
                        <td className="px-4 py-3">
                          {reimbursement?.promoCodeDetails?.description}
                        </td>
                      </>
                    )}
                    <td className="px-4 py-3">
                      {formatNumberWithCommas(reimbursement?.paidAmount | 0)}
                    </td>
                    <td className="px-4 py-3">
                      {new Date(
                        reimbursement?.approvedDate
                      )?.toLocaleDateString()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReimbursementTable;
