import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import { useState, useEffect } from "react";

const useGetData =  <T>(name:string, id?:string) => {
    const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  async function fetchData() {
    try {
      const response = await apiRequest(name, null, [id]);
      if (response.status === 200) {
        const fetchedData = response?.data || [];
        setData(fetchedData);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err: any) {
      message.error(err?.response?.data?.message ||   err?.message);
      setError(err?.response?.data?.message  || err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [name]);

  return { data, loading, error };
};

export default useGetData;
