import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import { Switcher } from "shared-components/ui/CustomForm";

const MyTargetView = ({ supervisor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (supervisor?._id) {
      fetchData();
    }
  }, [supervisor]);

  async function fetchData() {
    setIsLoading(true);

    try {
      const response = await CommissionAPI("getUserReferralsById", [
        supervisor?._id,
      ]);
      if (response) {
        const fetchedUser = response?.data?.data;
        setUser(fetchedUser);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading)
    return (
      <div className="mx-auto text-center text-gray-800  pb-5">Loading...</div>
    );
  if (!user)
    return (
      <div className="mx-auto text-center text-gray-800  pb-5">
        No data available
      </div>
    );

  return (
    <div className="px-2">
      {user?.referralTargets && user?.referralTargets?.length > 0 ? (
        user?.referralTargets?.map((target, index) => {
          const startDate = new Date(target?.startDate);
          const endDate = new Date(target?.endDate);
          const targetCompleted = target?.totalTargetDone;
          const targetInProgress = target?.target - targetCompleted;

          // Determine if the target is active
          const isActive = target.isActive;
          return (
            <div key={target._id} className={`mb-5 `}>
              <div className="flex items-center justify-between">
                <h2
                  className={`text-xl font-semibold ${
                    isActive ? "text-green-800" : "text-gray-800"
                  } mb-2`}
                >
                  {startDate.toLocaleString("default", { month: "long" })}{" "}
                  {startDate.getFullYear()}
                  {endDate
                    ? ` - ${endDate.toLocaleString("default", {
                        month: "long",
                      })} ${endDate.getFullYear()}`
                    : ""}
                  <span className="text-sm font-normal">
                    {"   "}
                    (Target Completed: {targetCompleted}/{target?.target} -{" "}
                    {targetInProgress} targets remaining)
                  </span>
                </h2>
                <Switcher
                  isActive={isActive}
                  onChange={() => {}}
                  for={target._id}
                  togglevalue={isActive}
                  size="small"
                  disabled
                />
              </div>
              <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th className="border border-gray-300 px-4 py-2">
                      User Name
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      User Email
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Bundle Purchased
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Bundle Status
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Paid Amount
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Commission Earned
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {target?.userIds?.map((userId, idx) => (
                    <tr key={userId?._id} className="text-center">
                      <td className="border border-gray-300 px-4 py-2">
                        {userId?.fullname}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {userId?.username}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">N/A</td>{" "}
                      {/* Assuming you replace this with actual data */}
                      <td className="border border-gray-300 px-4 py-2">
                        Active
                      </td>{" "}
                      {/* Assuming you replace this with actual status */}
                      <td className="border border-gray-300 px-4 py-2">
                        {userId?.userPaidAmount || "0"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {userId?.totalAmountEarned || "0"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })
      ) : (
        <>
          <h2
            className={`text-xl text-gray-800 font-semibold text-center pb-5`}
          >
            No Data Found!
          </h2>
        </>
      )}
    </div>
  );
};

export default MyTargetView;
