 

 import React, { useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const AuditedQuestionHistoryView = () => {
  const { id } = useParams();
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await apiRequest("getAuditQuestionById", null, [id]);
      if (res?.data?.data?.history?.length > 0) {
        const parsedData = res?.data?.data?.history?.map((history) => {
          return {
            ...history,
            newQuestion: JSON.parse(history?.newQuestion),
            oldQuestion: JSON.parse(history?.oldQuestion),
          };
        });
        setHistoryData(parsedData);
      }
      console.log("res is: ", res);
    };
    fetchData();
  }, [id]);
  const excludeFields = (obj, fieldsToExclude) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => excludeFields(item, fieldsToExclude));
    } else if (typeof obj === "object" && obj !== null) {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (!fieldsToExclude.includes(key)) {
          newObj[key] = excludeFields(obj[key], fieldsToExclude);
        }
      });
      return newObj;
    }
    return obj; // Return the value if it's not an object or array
  };
  const diffText = (newText, oldText) => {
    // Strip HTML tags for comparison
    const stripHTML = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");
    
    const strippedNewText = stripHTML(newText);
    const strippedOldText = stripHTML(oldText);
  
    const oldWords = strippedOldText.split(' ');
    const newWords = strippedNewText.split(' ');
  
    const result = newWords.map((word, index) => {
      if (word !== oldWords[index]) {
        return (
          <span key={index} className="text-green-500">
            {word}{' '}
          </span>
        );
      }
      return <span key={index}>{word} </span>;
    });
  
    const oldResult = oldWords.map((word, index) => {
      if (word !== newWords[index]) {
        return (
          <span key={index} className="text-red-500 line-through">
            {word}{' '}
          </span>
        );
      }
      return <span key={index}>{word} </span>;
    });
  
    return { newResult: result, oldResult: oldResult };
  };

  const formatValue = (newValue, oldValue) => {
    console.log("type", typeof newValue)
    console.log("type oldValue", typeof oldValue)

    if (Array.isArray(newValue)) {
      return (
        <ul className="list-disc ml-5">
          {newValue.map((item, index) => (
            <li key={index}>{formatValue(item, oldValue ? oldValue[index] : null)}</li>
          ))}
        </ul>
      );
    } else if (typeof newValue === 'object' && newValue !== null) {
      return (
        <div className="ml-4">
          {Object.entries(newValue).map(([key, val]) => (
            <div key={key}>
              <strong>{key}:</strong> {formatValue(val, oldValue ? oldValue[key] : null)}
            </div>
          ))}
        </div>
      );
    } else if (typeof newValue === 'string' && oldValue && typeof oldValue === 'string') {
    console.log("type in ", typeof newValue)

      // Apply text difference highlighting
      const { newResult, oldResult } = diffText(newValue, oldValue);
      console.log("newResult", newResult)
      console.log("oldResult", oldResult)
      return (
        <div className="flex space-x-4 mb-2">
          <div>
            <p>{newResult}</p>
          </div>
          <div className="line-through">
            <p>{oldResult}</p>
          </div>
        </div>
      );
    } else if(typeof newValue === 'string'){
      return <p dangerouslySetInnerHTML={{ __html:newValue }}></p>
    }
    return newValue;
  };
  
  const compareQuestions = (newQuestion, oldQuestion) => {
    // Exclude '_id' field from both the root and nested levels before comparison
    const fieldsToExclude = ["_id"];
    const filteredNewQuestion = excludeFields(newQuestion, fieldsToExclude);
    const filteredOldQuestion = excludeFields(oldQuestion, fieldsToExclude);
    console.log("filteredOldQuestion", filteredOldQuestion);
    console.log("filteredNewQuestion", filteredNewQuestion);
    // Simple comparison logic
    const newKeys = Object.keys(filteredNewQuestion);
    return newKeys.map((key) => {
      const newValue = filteredNewQuestion[key];
      const oldValue = filteredOldQuestion[key];

      // Ensure newValue and oldValue are rendered as strings
      const newValueDisplay =
        typeof newValue === "object" ? JSON.stringify(newValue) : newValue;
      const oldValueDisplay =
        typeof oldValue === "object" ? JSON.stringify(oldValue) : oldValue;

      // Only render if the values are different
      if (newValueDisplay !== oldValueDisplay) {
        return (
          <div key={key} className="flex space-x-4 mb-2">
            <div className="text-green-500">
              <strong>{key}:</strong> {formatValue(newValue, null)}
            </div>
            <div >
              <strong>{key}:</strong> <span className="text-red-500 line-through">{formatValue(oldValue, null)}</span> 
            </div>
          </div>
        );
      }
      return null; // Skip rendering if values are the same
    });
  };

  console.log("historyData", historyData);

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Audit Question History</h2>
      {historyData.length === 0 ? (
        <p>No history data available.</p>
      ) : (
        historyData.map((item, index) => (
          <div key={index} className="border p-4 mb-4 rounded shadow">
            <div className="flex justify-between ">
              <h3 className="text-lg font-semibold">Change {index + 1}</h3>
              <div className="flex flex-col gap-2">
                <div>
                {dayjs(item.dateChanged)?.format("dddd, DD YYYY  hh:mm a")}

                </div>
               <div> Modefied By :{item.modifiedBy}</div>
              </div>
            </div>
            {compareQuestions(item.newQuestion, item.oldQuestion)}
          </div>
        ))
      )}
    </div>
  );
};

export default AuditedQuestionHistoryView;
