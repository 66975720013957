import React from "react";
import { InfoIcon } from "shared-components/ui/Icons";
import { renderChips } from "shared-pages/latest-commission/utils";

const ViewSalesTable = ({
  orders,
  setCouponDetailsModal,
  supervisorOnly = false,
  supervisorEmail = "",
  showMySales = false,
  tableLayout = true,
  compactLayout = false,
}) => {
  return (
    <>
      {tableLayout ? (
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Order Id
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Purchased User Email
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                My Supervisor
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                At Commission %
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Total Amount Earned
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                My Coupon
              </th>

              <th scope="col" className="px-4 py-4 text-left">
                My Role
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Coupon Details:
              </th>
            </tr>
          </thead>
          <tbody>
            {orders
              ?.filter((order) => {
                // Filter by personalCoupon if showMySales is true
                const mySalesFilter = showMySales
                  ? order?.personalCoupon
                  : true;

                // Filter by supervisorEmail if supervisorOnly is true
                const supervisorFilter = supervisorOnly
                  ? order?.supervisor === supervisorEmail
                  : true;

                // Return true only if both filters pass
                return mySalesFilter && supervisorFilter;
              })
              ?.map((order) => {
                return (
                  <tr className="border-b" key={order?._id}>
                    <td className="px-4 py-3">{order?._id}</td>
                    <td className="px-4 py-3 text-left">
                      {order?.purchasedUserEmail}
                    </td>
                    <td className="px-4 py-3 text-left">{order?.supervisor}</td>
                    <td className="px-4 py-3 text-center">
                      {order?.myCommissionPercentage}%
                    </td>
                    <td className="px-4 py-3 text-center">
                      {order?.totalAmountEarned}
                    </td>
                    <td className="px-4 py-3 text-center">
                      {order?.personalCoupon ? "Yes" : "No"}
                    </td>
                    <td className="px-4 py-3 text-left">
                      {renderChips(order?.myRole)}
                    </td>
                    <td className="px-4 py-3 flex items-center justify-center">
                      <button
                        onClick={() => {
                          setCouponDetailsModal({
                            isOpen: true,
                            data: {
                              couponUser: order?.couponUser,
                              code: order?.code,
                              purchasedUserEmail: order?.purchasedUserEmail,
                            },
                          });
                        }}
                      >
                        <InfoIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <>
          {orders
            ?.filter((order) => {
              // Apply the filter only if showMySales is true
              return showMySales ? order?.personalCoupon : true;
            })
            ?.map((order) => {
              return (
                <div className="border-b flex w-full" key={order?._id}>
                  {compactLayout ? (
                    <>
                      <span className="px-4 py-3">{order?._id}</span>
                      {/* <span className="px-4 py-3 text-left">
                        {order?.purchasedUserEmail}
                      </span> */}
                      {/* <span className="px-4 py-3 text-left">
                        {order?.supervisor}
                      </span> */}
                      <span className="px-4 py-3 text-center">
                        {order?.myCommissionPercentage}%
                      </span>
                      <span className="px-4 py-3 text-center">
                        {order?.totalAmountEarned}
                      </span>
                      <span className="px-4 py-3 text-center">
                        {order?.personalCoupon ? "Yes" : "No"}
                      </span>
                      {/* <span className="px-4 py-3 text-left">
                        {renderChips(order?.myRole)}
                      </span> */}
                      <span className="px-4 py-3 flex items-center justify-center">
                        <button
                          onClick={() => {
                            setCouponDetailsModal({
                              isOpen: true,
                              data: {
                                couponUser: order?.couponUser,
                                code: order?.code,
                                purchasedUserEmail: order?.purchasedUserEmail,
                              },
                            });
                          }}
                        >
                          <InfoIcon />
                        </button>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="px-4 py-3">{order?._id}</span>
                      <span className="px-4 py-3 text-left">
                        {order?.purchasedUserEmail}
                      </span>
                      <span className="px-4 py-3 text-left">
                        {order?.supervisor}
                      </span>
                      <span className="px-4 py-3 text-center">
                        {order?.myCommissionPercentage}%
                      </span>
                      <span className="px-4 py-3 text-center">
                        {order?.totalAmountEarned}
                      </span>
                      <span className="px-4 py-3 text-center">
                        {order?.personalCoupon ? "Yes" : "No"}
                      </span>
                      <span className="px-4 py-3 text-left">
                        {renderChips(order?.myRole)}
                      </span>
                      <span className="px-4 py-3 flex items-center justify-center">
                        <button
                          onClick={() => {
                            setCouponDetailsModal({
                              isOpen: true,
                              data: {
                                couponUser: order?.couponUser,
                                code: order?.code,
                                purchasedUserEmail: order?.purchasedUserEmail,
                              },
                            });
                          }}
                        >
                          <InfoIcon />
                        </button>
                      </span>
                    </>
                  )}
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default ViewSalesTable;
