import React from "react";
import { useState, useEffect, useContext } from "react";

import { message, notification } from "antd";

// @ts-ignore TODO
import styles from "./styles.module.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import { ViewModal } from "shared-pages/doubtquestion/components/view-modal";
import Loader from "shared-components/Loader";

export default function ExpertSubject() {
  const { subject } = useParams();
  const { user } = useContext(UserContext);
  const [Doubts, setDoubts] = useState<any[]>([]);
  const [ViewDoubt, SetViewDoubt] = useState<Boolean>(false);
  const [Question, SetQuestion] = useState<Object | null>();
  const [DoubtWithId, SetDoubtWithId] = useState<any>();
  const [NumberofSolved, SetNumberofSolved] = useState(0);
  const [NumberofPending, SetNumberOfPending] = useState(0);

  const [DoubtDisplay, SetDoubtDisplay] = useState({});

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalDocCount, setTotalDocCount] = useState<number>(0);
  const fetchDoubts = async (pageNumber = 0) => {
    try {
      setLoading(true);
      const { data } = await apiRequest("GetSubjectiveExpertDoubts", {
        subject: subject,
        username: user?.username,
        page: pageNumber,
      });
      setTotalDocCount(data?.totalDocumentCount);
      setDoubts(data?.filteredDoubts);
      SetNumberofSolved(data.SolvedDocumentCounts);
      SetNumberOfPending(data.PendingDocumentCounts);
    } catch (error) {
     message.error(error?.response?.data?.error ||  error.message)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(user.username){
      fetchDoubts(page + 1);
    }
  }, [page, user.username]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDoubt = (data) => {
    apiHandler({ name: "GetQuestion", query: { question: data?._id } }).then(
      (res) => {
        if (res.data?.Error) {
          notification.error({
            message: "Error",
            description: res.data?.Error,
          });
        } else {
          SetQuestion(res.data);
        }
      }
    );
    SetViewDoubt(true);
    SetDoubtDisplay(data);
    SetDoubtWithId(data._id);
  };
  const history = useNavigate();

  const TakeDoubt = async () => {
    history(`/SolveDoubt/${DoubtWithId?._id}`, {
      state: {
        Doubt: Question[0],
        Params: subject,
        DoubtWithId,
      },
    });

    fetchDoubts();
  };

  const [skipModal, setSkipModal] = useState(false);
  const [skipCount, setSkipCount] = useState<number>(0);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <>
          <h3 className="text-center mt-2 text-3xl font-semibold">Expert Dashboard</h3>
          <div className="container my-4 border-2 rounded p-4 ">
            <div className="bg-rose-50 p-4 mb-2 rounded  ">
              <div className="flex justify-between items-center mb-2">
                <h6 className="text-left">
                  Available Questions for {user?.fullname}
                </h6>
                <div className="flex items-center flex-end gap-2">
                  <button
                    className="bg-rose-500 rounded-md text-white px-4 py-1 rounded hover:bg-rose-200"
                    onClick={() => setSkipModal(true)}
                  >
                    Skip to Page
                  </button>

                  <button
                    className="bg-rose-500 text-white px-4 py-1 rounded hover:bg-rose-200"
                    onClick={() => history(`/expertdashboard/${subject}`)}
                  >
                    Dashboard
                  </button>
                </div>
              </div>
              <div>
                <div className="flex justify-between items-center">
                  {NumberofSolved ? (
                    <h6 className="text-left">
                      Solved Questions: {NumberofSolved}
                    </h6>
                  ) : (
                    <h6 className="text-left">Solved Questions: 0</h6>
                  )}
                  <h6 className="text-left">
                    {/* Pending Questions: {NumberofPending} */}
                    Pending Questions: {totalDocCount}
                  </h6>
                </div>
              </div>
            </div>

            {loading ? (
           <Loader loading={loading} />
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow className="bg-rose-500 p-4 mb-2 !rounded-md">
                        <TableCell
                          className="text-left text-red-500!important"
                          style={{ width: "45%" }}
                        >
                          Tags
                        </TableCell>
                        <TableCell
                          className="text-left text-red-500"
                          style={{ width: "45%" }}
                        >
                          Question Text
                        </TableCell>
                        <TableCell
                          className="text-left text-red-500"
                          style={{ width: "4%" }}
                        >
                          {" "}
                          Action{" "}
                        </TableCell>
                        <TableCell className="text-left text-red-500">
                          Edit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {Doubts.map((doubt) => (
                          <TableRow key={doubt.id} className="table-row">
                            <TableCell id={styles.TagCell}>
                              {doubt.Tags.map((tag) => (
                                <div className="bg-rose-50 px-2 py-1 rounded-md mr-2 text-sm text-red-500 border border-rose-500">
                                  {tag.name}
                                </div>
                              ))}
                            </TableCell>
                            <TableCell
                              id="table-cell"
                              style={{ cursor: "pointer" }}
                            >
                              {doubt.QuestionText.slice(0, 55)
                                ?.replace("<p>", "")
                                .replace("</p>", "")
                                .replace("<span", "")
                                .replace("</span>", "") + "..."}
                            </TableCell>
                            <TableCell id="table-cell">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-500 hover:text-rose-700"
                                onClick={() => {
                                  handleViewDoubt(doubt);
                                  SetDoubtWithId(doubt);
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                />
                              </svg>
                            </TableCell>
                            <TableCell id="table-cell">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-500 hover:text-rose-700"
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_CATEGORY !=='MEDSCHOOL'?"https://premed-admin.parho.io":"https://medschool-admin.parho.io"}/editQuestion/${doubt.QuestionID}`,
                                    "_blank"
                                  );
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[100]}
                          component="div"
                          count={totalDocCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={(event, newPage) => {
                            // console.log("New Page:", newPage);
                            handleChangePage(event, newPage);
                          }}
                          onRowsPerPageChange={(event) => {
                            // console.log("Rows Per Page:", event.target.value);
                            handleChangeRowsPerPage(event);
                          }}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                {skipModal && (
                  <div
                    id="skip-modal"
                    aria-hidden="true"
                    className={`${
                      skipModal ? "block" : "hidden"
                    }overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mt-8`}
                  >
                    <div className="relative p-4 w-full max-w-2xl max-h-full flex justify-center items-center mx-auto my-8">
                      <div className="relative bg-white rounded-lg shadow border dark:border-gray-600 w-full flex flex-col p-4">
                        <div className="flex items-center justify-between p-4 pb-2 border-b rounded-t dark:border-gray-600">
                          <h3 className="text-xl font-semibold text-gray-900">
                            Skip Questions
                          </h3>
                          <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="skip-modal"
                            onClick={() => {
                              setSkipModal(false);
                            }}
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                        </div>
                        <input
                          type="number"
                          value={skipCount}
                          placeholder="Enter to any page"
                          className="p-2 border border-gray-100 dark:border-gray-600 rounded-md mt-2"
                          onChange={(e) => {
                            if (parseInt(e.target.value) > totalDocCount) {
                              message.error("Page number exceeds total number of questions")
                            } else {
                              setSkipCount(parseInt(e.target.value));
                            }
                          }}
                        />
                        <div className="flex items-center rounded-b dark:border-gray-600">
                          <div className={`container ${styles.ButtonHolder}`}>
                            <button
                              className={styles.ButtonBack}
                              onClick={() => {
                                setSkipModal(false);
                              }}
                            >
                              Back
                            </button>
                            <button
                              className={styles.ButtonNew}
                              onClick={() => {
                                fetchDoubts(skipCount);
                                setSkipModal(false);
                                setPage(skipCount - 1);
                                // SkipToPage(parseInt(skipCount));
                                // setSkipCount(0);
                              }}
                            >
                              Skip
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
        {ViewDoubt && (
          <>
            <ViewModal
              data={DoubtDisplay}
              open={ViewDoubt}
              SetViewDoubt={SetViewDoubt}
              SetDoubtDisplay={SetDoubtDisplay}
              SetQuestion={SetQuestion}
              TakeDoubt={TakeDoubt}
            />
          </>
        )}
      </div>
    </>
  );
}
