import React, { useEffect, useState } from "react";
import { Input, Label } from "../../../../shared-components/ui/CustomForm";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
import toast, { Toaster } from "react-hot-toast";
import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";
import { Select } from "antd";
import { ConfirmationModal } from "../../../../shared-components/ui/CustomModal";
import { getUserIdField, getUsersOfSpecificRole, ROlES } from "../../utils";

const TransferUserModal = ({
  allCommissionUsers,
  setModal,
  modal,
  type,
  transferringRole,
  supervisorRole,
}) => {
  console.log(modal);

  const initialState = {
    currentSupervisorEmail: "",
    newSupervisorEmail: "",
    role: transferringRole ? transferringRole : "",
    specificEntityIds: [],
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  //head Emails
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    if (allCommissionUsers && type) {
      if (type === "transfer-associates-from-one-manager-to-another-manager") {
        // we have to fetch Managers so we can display it on dropdown
        const emails = getUsersOfSpecificRole(
          allCommissionUsers,
          ROlES.MANAGER
        );
        const formattedEmails = emails?.map?.((user: any) => user.email);
        setSupervisors(formattedEmails);
      } else if (type === "transfer-managers-from-one-director-to-another-director") {
        // we have to fetch Directors so we can display it on dropdown
        const emails = getUsersOfSpecificRole(
          allCommissionUsers,
          ROlES.DIRECTOR
        );
        const formattedEmails = emails?.map?.((user: any) => user.email);
        setSupervisors(formattedEmails);
      }
    }
  }, [allCommissionUsers, type]);

  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetAll = () => {
    setFormData(initialState);
    setModal({ isOpen: false, type: "", data: null });
  };

  // const numberOfUsersWorkingUndercurrentSupervisorEmail =
  //   allCommissionUsers
  //     ?.find((user) => user?.email === formData.currentSupervisorEmail)
  //     ?.getUserIdField(supervisorRole)?.length || 0;
  // const numberOfUsersWorkingUnderNewSupervisorEmail =
  //   allCommissionUsers
  //     ?.find((user) => user?.email === formData.newSupervisorEmail)
  //     ?.getUserIdField(supervisorRole)?.length || 0;

  const getUserIdsByRole = (users: any[], email: string, role: string) => {
    const user = users.find((user) => user.email === email);
    if (!user) return 0;

    const userIdField = getUserIdField(role);
    if (!userIdField) return 0;

    return user[userIdField]?.length || 0;
  };

  const numberOfUsersWorkingUndercurrentSupervisorEmail = getUserIdsByRole(
    allCommissionUsers,
    formData.currentSupervisorEmail,
    supervisorRole
  );

  const numberOfUsersWorkingUnderNewSupervisorEmail = getUserIdsByRole(
    allCommissionUsers,
    formData.newSupervisorEmail,
    supervisorRole
  );

  const Submit = async () => {
    if (
      !formData?.currentSupervisorEmail ||
      formData?.currentSupervisorEmail?.trim() === ""
    ) {
      toast.error(`Current ${supervisorRole} Email is required`);
      return;
    } else if (
      !formData?.newSupervisorEmail ||
      formData?.newSupervisorEmail?.trim() === ""
    ) {
      toast.error(`New ${supervisorRole} Email is required`);
      return;
    }
    setIsLoading(true);
    try {
      // setIsLoading(true);
      const data: any = formData;
      if (!transferringRole) {
        toast.error("Something went wrong, Role not defined");
        return;
      }
      if (transferringRole) {
        data.role = transferringRole;
      }

      console.log("Req Body: ", data);

      // return;
      let response = null;
      // if (type === "transfer-associates-from-one-head-to-another-head") {
      response = await CommissionAPI("moveEntitiesToNewSupervisor", [], data);
      // }

      console.log("response: ", response);

      if (response) {
        // const message = response?.data?.message;
        toast.success("Transfered Successfully 🚀");
        setTimeout(() => {
          resetAll();
        }, 1000);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  console.log("🔰-formData: ", formData);

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        modal?.isOpen ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <Toaster />
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">Transfer</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setModal({ isOpen: false, type: "", data: null });
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-2">
            <div className="">
              <Label>Select Current {supervisorRole} Email</Label>
              <Select
                showSearch
                onChange={(selectedEmail) => {
                  handleInputChange("currentSupervisorEmail", selectedEmail);
                }}
                // g
                size={"large"}
                disabled={isLoading}
                className="w-full"
                value={formData.currentSupervisorEmail}
              >
                {supervisors?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>

              {formData?.currentSupervisorEmail?.trim() !== "" ? (
                <span className="text-sm text-amber-800 mt-2 inline-block">
                  {`Number of ${transferringRole}s working under ` +
                    formData.currentSupervisorEmail +
                    " = " +
                    numberOfUsersWorkingUndercurrentSupervisorEmail}
                </span>
              ) : null}
            </div>

            <div className="">
              <Label>Select New {supervisorRole} Email</Label>
              <Select
                showSearch
                onChange={(selectedEmail) => {
                  handleInputChange("newSupervisorEmail", selectedEmail);
                }}
                // g
                size={"large"}
                disabled={isLoading}
                className="w-full"
                value={formData.newSupervisorEmail}
              >
                {supervisors?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>

              {formData?.newSupervisorEmail?.trim() !== "" ? (
                <span className="text-sm text-amber-800 mt-2 inline-block">
                  {`Number of ${transferringRole}s working under ` +
                    formData.newSupervisorEmail +
                    " = " +
                    numberOfUsersWorkingUnderNewSupervisorEmail}
                </span>
              ) : null}
            </div>

            <div className="flex items-center justify-end gap-2">
              <ButtonOutlined
                handleClick={() => {
                  resetAll();
                }}
              >
                Cancel
              </ButtonOutlined>
              <ButtonFill
                isLoading={isLoading}
                handleClick={Submit}
                disabled={isLoading}
              >
                {isLoading ? "Loading.." : "Submit"}
              </ButtonFill>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferUserModal;
