import CustomBadges from "shared-components/ui/CustomBadges";
import {
  getTeamLength,
  getTotalAmountEarned,
  renderCellColor,
  renderChips,
} from "shared-pages/latest-commission/utils";
import ViewSalesTable from "./view-sales-table";

const getUserIdField = (role) => {
  switch (role) {
    case "NATIONAL_DIRECTOR":
      return "provincialDirectorIds";
    case "PROVINCIAL_DIRECTOR":
      return "managerIds";
    case "DIRECTOR":
      return "managerIds";
    case "MANAGER":
      return "associateIds";
    case "HEAD":
      return "associateIds";
    default:
      return null;
  }
};

const NestedOrders = ({
  users,
  showOrders,
  setCouponDetailsModal,
  //   renderChips,
  //   setDisciplineModal,
  //   setToBeViewDiscipline,
  level,
}) => {
  // console.log("UserCard2 users: ", users);
  return (
    <>
      {users &&
        users
          // ?.slice((currentPage - 1) * PerPage, currentPage * PerPage)
          ?.map((user) => {
            //Let's suppose Head ko Promote kra Manager pr along with associates like manager directly, then ham dekhenge ke wo promote konse role se hoa he, is case me wo HEAD se promote hoa he Manager pr, then ab hm uske headIds ko access nhi krenge bulke uske associateIds ko access krenge cause wo manager to he but uske under me Head nhi he bulke wo direclty ab associates ko handle kr raha he
            const fieldName = user?.isPromoted
              ? getUserIdField(user?.promotedFrom)
              : getUserIdField(user?.role);
            // console.log("fieldName: ", fieldName);
            return (
              <>
                <table
                  style={{
                    width: 100 - level * 2 + "%",
                    marginLeft: "auto",
                  }}
                  className="w-full  text-sm text-left text-gray-700"
                >
                  <thead
                    className={`text-xs text-gray-800 uppercase  ${renderCellColor(
                      user?.role
                    )}`}
                  >
                    <tr>
                      <th scope="col" className="px-4 py-1">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Role
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-center">
                        Report Info
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Report to
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Team
                      </th>

                      <th scope="col" className="px-4 py-1.5 text-center">
                        Commision%
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-center">
                        Amount Earned
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className={`border-b text-xs`}
                      style={{
                        marginLeft: level * 8 + "px",
                      }}
                      key={user?._id}
                    >
                      <td className="px-4 py-3">{user?.name}</td>
                      <td className="px-4 py-3">{user?.email}</td>
                      <td className="px-4 py-3 text-left">
                        {renderChips(user?.role)}
                      </td>

                      <td className="px-4 py-3">
                        {user?.reportTo?.email}{" "}
                        {user?.reportTo?.name
                          ? "- " + user?.reportTo?.name
                          : ""}
                      </td>
                      <td className="px-4 py-3 text-left">
                        {renderChips(user?.reportTo?.role)}
                      </td>
                      <td className="px-4 py-3 text-left">
                        {getTeamLength(user, user?.role)}
                      </td>

                      <td className="px-4 py-3 text-center">
                        <CustomBadges
                          type="warning"
                          label={user?.commissionPercentage}
                          showIcon={false}
                        />
                      </td>
                      <td className="px-4 py-3 text-center">
                        {getTotalAmountEarned(user)}
                      </td>
                    </tr>

                    {showOrders ? (
                      <tr className="w-full ">
                        <td colSpan={8} className="w-full ">
                          <ViewSalesTable
                            orders={user?.orders}
                            setCouponDetailsModal={setCouponDetailsModal}
                            showMySales
                            tableLayout={false}
                            compactLayout={true}
                            supervisorOnly
                            supervisorEmail={user?.email}
                          />
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                {user[fieldName] && user[fieldName]?.length > 0 && (
                  <NestedOrders
                    users={user[fieldName]}
                    level={level + 1}
                    showOrders
                    setCouponDetailsModal
                  />
                )}
              </>
            );
          })}
    </>
  );
};
export default NestedOrders;
