// import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import TargetModal from "./TargetModal";
import {
  getUserIdField,
  ROlE_WORKING_UNDER,
  // getUsersOfSpecificRole,
  // ROlES,
} from "shared-pages/latest-commission/utils";
import ViewUserTargetTable from "./view-user-target-table";
import MyTargetView from "./my-target-view";

const TargetManagement = ({ supervisor, screen }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const initialModalState = {
    isModalOpen: false,
    // type: "ADD",
    // for: supervisor || ROlES.ASSOCIATE,
  };
  const [modal, setModal] = useState(initialModalState);

  const closeModal = () => {
    setModal(initialModalState);
  };
  const openModal = () => {
    setModal({
      isModalOpen: true,
      // type: "ADD",
      // for: supervisor || ROlES.ASSOCIATE,
    });
  };

  useEffect(() => {
    if (supervisor) {
      let supervisorRole = supervisor?.role;
      let fieldName = getUserIdField(supervisorRole);
      if (fieldName) {
        let usersWorkingUnderCurrentSupervisor = supervisor[fieldName];
        setAllUsers(usersWorkingUnderCurrentSupervisor);
      }
    }
  }, [supervisor]);

  return (
    <div>
      {screen === "Target Management" && (
        <>
          <div
            className=" min-w-80 w-80 shadow-lg rounded-md ml-auto  text-white bg-gradient-to-r from-primary-400 via-primary-500 to-primary-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-primary-300  font-medium  text-sm p-5 text-center me-2 mb-2 cursor-pointer"
            onClick={() => {
              openModal();
            }}
          >
            <h1 className="font-semibold">
              Create Target for {ROlE_WORKING_UNDER[supervisor?.role]}S
            </h1>
          </div>
          <TargetModal
            allUsers={allUsers}
            modal={modal}
            closeModal={closeModal}
          />

          <ViewUserTargetTable allUsers={allUsers} />
        </>
      )}
      {screen === "My Target" && <MyTargetView supervisor={supervisor} />}
    </div>
  );
};

export default TargetManagement;

// async function fetchData() {
//   setIsLoading(true);
//   try {
//     const [commissionUsersResponse] = await Promise.all([
//       CommissionAPI("getAllCommissionUsers"),
//     ]);

//     if (commissionUsersResponse.status === 200) {
//       const commissionUsersData = commissionUsersResponse?.data?.data || [];
//       let filterUserRole = ROlES.ASSOCIATE;
//       let supervisorRole = supervisor?.role;
//       console.log("commissionUsersData: ", commissionUsersData);
//       let field = getUserIdField(supervisorRole);

//       console.log(
//         "filterUserRole: ",
//         filterUserRole,
//         "supervisorRole : ",
//         supervisorRole,
//         "field: ",
//         field,
//         supervisor[field],
//         supervisor
//       );
//       if (supervisor?.role === ROlES.MANAGER) {
//         filterUserRole = ROlES.ASSOCIATE;
//       } else if (supervisor?.role === ROlES.DIRECTOR) {
//         filterUserRole = ROlES.MANAGER;
//       }
//       const filteredUsers = getUsersOfSpecificRole(
//         commissionUsersData,
//         filterUserRole
//       );

//       const usersWorkingUnderCurrentSupervisor = filteredUsers?.filter(
//         (user: any) => supervisor[field]?.includes(user?._id)
//       );

//       setAllUsers(usersWorkingUnderCurrentSupervisor);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   } finally {
//     setIsLoading(false);
//   }
// }

// useEffect(() => {
//   fetchData();
// }, [supervisor]);
