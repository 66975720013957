import { useState } from "react";
import {
  EditIcon,
  ExternalLinkIcon,
  PreviewIcon,
} from "../../../shared-components/ui/Icons";
import CommissionUserForm from "./CommissionUserForm";
import CustomBadges from "../../../shared-components/ui/CustomBadges";
import { Toaster } from "react-hot-toast";
import { renderCellColor, renderChips, getTotalAmountEarned } from "../utils";

export const ViewCommissionUser = ({
  allUsers,
  setAllUsers,
  adminUsers,
  nestedStructure,
  reportToUsers,
  allAdminUsers,
  allCommissionUsers,
  label,
}) => {
  // console.log("reportToUsers: ", reportToUsers);
  // console.log("allUsers: ", allUsers, " adminUsers: ", adminUsers);
  const [openDisciplineFormModal, setDisciplineModal] = useState(false);
  const [toBeViewCommissionUser, setToBeViewDiscipline] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const PerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getTeamLength = (user, role) => {
    const updateFields = {
      NATIONAL_DIRECTOR: "provincialDirectorIds",
      PROVINCIAL_DIRECTOR: "managerIds",
      DIRECTOR: "managerIds",
      MANAGER: "associateIds",
      HEAD: "associateIds",
      ASSOCIATE: null,
    };

    const fieldName = updateFields[role];

    if (fieldName && user[fieldName]) {
      return user[fieldName].length;
    }

    return 0;
  };

  const filteredUsers = reportToUsers;

  // console.log("Supervisors Report to: ", reportToUsers);

  return (
    <>
      <div className="overflow-x-auto">
        <Toaster />

        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Email
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Role
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Report Info
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Report to
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Team
              </th>

              {/* <th scope="col" className="px-4 py-4 text-center">
                Active
              </th> */}
              <th scope="col" className="px-4 py-4 text-center">
                Commision%
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Amount Earned
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {allUsers
              // ?.slice((currentPage - 1) * PerPage, currentPage * PerPage)
              ?.map((user) => (
                <tr className="border-b" key={user?._id}>
                  <td className="px-4 py-3">{user?.name}</td>
                  <td className="px-4 py-3">{user?.email}</td>
                  <td className="px-4 py-3 text-left">
                    {renderChips(user?.role)}
                  </td>

                  <td className="px-4 py-3">
                    {user?.reportTo?.email}{" "}
                    {user?.reportTo?.name ? "- " + user?.reportTo?.name : ""}
                  </td>
                  <td className="px-4 py-3 text-left">
                    {renderChips(user?.reportTo?.role)}
                  </td>
                  <td className="px-4 py-3 text-left">
                    {getTeamLength(user, user?.role)}
                  </td>
                  {/* <td className="px-4 py-3">
                    <div className="mx-auto w-fit">
                      <Switcher
                        for={user?._id}
                        onChange={() => {
                          UpdateStatus(user?._id, user?.isPublished);
                        }}
                        togglevalue={user?.isPublished}
                        size="small"
                      />
                    </div>
                  </td> */}
                  <td className="px-4 py-3 text-center">
                    <CustomBadges
                      type="warning"
                      label={user?.commissionPercentage}
                      showIcon={false}
                    />
                  </td>
                  <td className="px-4 py-3 text-center">
                    {getTotalAmountEarned(user)}
                  </td>
                  <td className="relative flex items-center justify-center px-4 py-3">
                    <button
                      type="button"
                      className="flex items-center justify-center w-full px-4 py-2 space-x-2 text-gray-700 hover:bg-gray-100"
                      onClick={() => {
                        setDisciplineModal(true);
                        setToBeViewDiscipline(user);
                      }}
                    >
                      <EditIcon />
                      <span>Edit</span>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>

          <CommissionUserForm
            mode="edit"
            active={openDisciplineFormModal}
            setModal={setDisciplineModal}
            onCancel={() => {
              setDisciplineModal(false);
            }}
            onConfirm={() => {
              setDisciplineModal(false);
            }}
            commissionUser={toBeViewCommissionUser}
            setDiscipline={setAllUsers}
            allUsers={allUsers}
            adminUsers={adminUsers}
            reportToUsers={filteredUsers}
            label={label}
          />
        </table>

        <UserCard2
          getTeamLength={getTeamLength}
          renderChips={renderChips}
          setDisciplineModal={setDisciplineModal}
          setToBeViewDiscipline={setToBeViewDiscipline}
          users={nestedStructure}
          level={0}
        />
      </div>
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 space-x-2">
          <span> Showing</span>
          <span className="font-semibold text-gray-900 ">
            {currentPage * PerPage - PerPage + 1}
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">{allUsers.length}</span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <button
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
          {Array.from({
            length: Math.ceil(allUsers.length / PerPage),
          }).map((_, index) => (
            <li key={index}>
              <button
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 ${
                  currentPage === index + 1
                    ? "font-semibold text-primary-600 bg-primary-50"
                    : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

const getUserIdField = (role) => {
  switch (role) {
    case "NATIONAL_DIRECTOR":
      return "provincialDirectorIds";
    case "PROVINCIAL_DIRECTOR":
      return "managerIds";
    case "DIRECTOR":
      return "managerIds";
    case "MANAGER":
      return "associateIds";
    case "HEAD":
      return "associateIds";
    default:
      return null;
  }
};

const UserCard2 = ({
  users,
  renderChips,
  getTeamLength,
  // UpdateStatus,
  setDisciplineModal,
  setToBeViewDiscipline,
  level,
}) => {
  // console.log("UserCard2 users: ", users);
  return (
    <>
      {users &&
        users
          // ?.slice((currentPage - 1) * PerPage, currentPage * PerPage)
          ?.map((user) => {
            //Let's suppose Head ko Promote kra Manager pr along with associates like manager directly, then ham dekhenge ke wo promote konse role se hoa he, is case me wo HEAD se promote hoa he Manager pr, then ab hm uske headIds ko access nhi krenge bulke uske associateIds ko access krenge cause wo manager to he but uske under me Head nhi he bulke wo direclty ab associates ko handle kr raha he
            const fieldName = user?.isPromoted
              ? getUserIdField(user?.promotedFrom)
              : getUserIdField(user?.role);
            // console.log("fieldName: ", fieldName);
            return (
              <>
                <table
                  style={{
                    width: 100 - level * 2 + "%",
                    marginLeft: "auto",
                  }}
                  className="w-full  text-sm text-left text-gray-700"
                >
                  <thead
                    className={`text-xs text-gray-800 uppercase  ${renderCellColor(
                      user?.role
                    )}`}
                  >
                    <tr>
                      <th scope="col" className="px-4 py-1">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Role
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-center">
                        Report Info
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Report to
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-left">
                        Team
                      </th>

                      {/* <th scope="col" className="px-4 py-1.5 text-center">
                        Active
                      </th> */}
                      <th scope="col" className="px-4 py-1.5 text-center">
                        Commision%
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-center">
                        Amount Earned
                      </th>
                      <th scope="col" className="px-4 py-1.5 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className={`border-b text-xs`}
                      style={{
                        marginLeft: level * 8 + "px",
                      }}
                      key={user?._id}
                    >
                      <td className="px-4 py-3">{user?.name}</td>
                      <td className="px-4 py-3">{user?.email}</td>
                      <td className="px-4 py-3 text-left">
                        {renderChips(user?.role)}
                      </td>

                      <td className="px-4 py-3">
                        {user?.reportTo?.email}{" "}
                        {user?.reportTo?.name
                          ? "- " + user?.reportTo?.name
                          : ""}
                      </td>
                      <td className="px-4 py-3 text-left">
                        {renderChips(user?.reportTo?.role)}
                      </td>
                      <td className="px-4 py-3 text-left">
                        {getTeamLength(user, user?.role)}
                      </td>
                      {/* <td className="px-4 py-3">
                        <div className="mx-auto w-fit">
                          <Switcher
                            for={user?._id}
                            onChange={() => {
                              UpdateStatus(user?._id, user?.isPublished);
                            }}
                            togglevalue={user?.isPublished}
                            size="small"
                          />
                        </div>
                      </td> */}
                      <td className="px-4 py-3 text-center">
                        <CustomBadges
                          type="warning"
                          label={user?.commissionPercentage}
                          showIcon={false}
                        />
                      </td>
                      <td className="px-4 py-3 text-center">
                        {getTotalAmountEarned(user)}
                      </td>
                      <td className="relative flex items-center justify-center px-4 py-3">
                        <button
                          type="button"
                          className="flex items-center justify-center w-full text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            setDisciplineModal(true);
                            setToBeViewDiscipline(user);
                          }}
                        >
                          <EditIcon />
                          {/* <span>Edit</span> */}
                        </button>
                        <button
                          type="button"
                          className="flex items-center justify-center w-full text-gray-700 hover:bg-gray-100"
                          onClick={() => {
                            window.open(
                              `/manage/commission/view/${user?._id}`,
                              "_blank"
                            );
                          }}
                        >
                          <ExternalLinkIcon />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {user[fieldName] && user[fieldName]?.length > 0 && (
                  <UserCard2
                    // UpdateStatus={UpdateStatus}
                    getTeamLength={getTeamLength}
                    renderChips={renderChips}
                    setDisciplineModal={setDisciplineModal}
                    setToBeViewDiscipline={setToBeViewDiscipline}
                    users={user[fieldName]}
                    level={level + 1}
                  />
                )}
              </>
            );
          })}
    </>
  );
};

export default ViewCommissionUser;
