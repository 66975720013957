import { apiRequest } from "(apis)/api-interface/api-handler";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AxiosResponse } from "axios";
import GlobalTable from "shared-components/table/GlobalTable";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DoubtQuestion from "../../components/doubt-question";

import {
  ApiResponseTypeData,
  DoubtResponseType,
  ExpertTableInterface,
} from "./types";
import { AdminManagerExpertColumn } from "./admin-manager-column";
import { EyeIcon } from "shared-components/ui/Icons";

const AdminManager = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ExpertTableInterface[]>([]);
  const [viewDoubt, setViewDoubt] = useState<string | null>(null);
  const [DoubtDisplay, SetDoubtDisplay] = useState<DoubtResponseType | null>(
    null
  );
  const column = useMemo<typeof AdminManagerExpertColumn>(() => {
    let actions = {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "100px",
      render: (_: any, record: ExpertTableInterface) => (
        <>
          <div
            onClick={() => {
              handleDoubtImage(record?.id);
            }}
          >
            <EyeIcon />
          </div>
        </>
      ),
    };
    return AdminManagerExpertColumn.concat(actions);
  }, []);

  const moveWordToEnd = (tags: string[], word: string): string[] => {
    const filteredTags = tags.filter(
      (tag) => tag.toLocaleLowerCase() !== word.toLocaleLowerCase()
    );
    const wordCount = tags.length - filteredTags.length;
    return [...filteredTags, ...Array(wordCount).fill(word)];
  };

  const formatData = useCallback(
    (res: AxiosResponse<ApiResponseTypeData[]>) => {
      return res?.data?.map((item) => ({
        ...item,
        tags: moveWordToEnd(
          [item.resource, item.subject, item.topic].filter(
            (i) => i !== null && i !== undefined
          ),
          "Others"
        ),
        source: item?.questionID ? "Premed QBank" : "Own Question",
        description:
          item.description?.length > 30
            ? item.description.slice(0, 30) + "..."
            : item.description,
        id: item?._id,
      }));
    },
    []
  );

  useEffect(() => {
    setLoading(true);
    apiRequest<ApiResponseTypeData[]>("fetchDoubts")
      .then((res) => {
        const formattedData = formatData(res);
        setData(formattedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [formatData]);

  const handleDoubtImage = (id: string): void => {
    setViewDoubt(id);
    getImage(id);
  };

  const getImage = (id: string): void => {
    apiRequest<DoubtResponseType>(`GetImage`, { id })
      .then((res) => {
        SetDoubtDisplay(res?.data);
        // const imageSrc = ImageToBlob(res?.data?.img as string);
        // SetImage(imageSrc);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {!viewDoubt && (
        <GlobalTable
          columns={column}
          data={data}
          loading={loading}
          tableName="Admin Manager"
          // isExpandable
        />
      )}
      {viewDoubt && (
        <DoubtQuestion
          DoubtDisplay={DoubtDisplay}
          setViewDoubt={setViewDoubt}
          setDoubtDisplay={SetDoubtDisplay}
        />
      )}
    </div>
  );
};

export default AdminManager;
